import React, { lazy } from 'react'
import LazyLoader from '../../Components/LazyComponents/LazyLoader'

const lazyImport = <T extends React.ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
  fallback: React.ReactNode = <></>
) => {
  const LazyComponent = lazy(importFunc)
  const ComponentWithLoader: React.FC<React.ComponentProps<T>> = (props) => (
    <LazyLoader fallback={fallback}>
      <LazyComponent {...props} />
    </LazyLoader>
  )

  return ComponentWithLoader
}

export default lazyImport
