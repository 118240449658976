import { styled } from '@mui/system'
import { colors, fontSizes } from '../../Constants/consts'
type StyleProps = {
  color: 'red' | 'blue' | 'green'
}

export const SnackBarStyle = styled('div')<StyleProps>(({ color, theme }) => ({
  display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
    color === 'red'
        ? `${colors.red}90`
        : color === 'blue'
        ? `${colors.lightBlue}90`
        : `${colors.green}90`,
    width: '50vw',
    margin: theme.spacing(1),
    borderRadius: 10,
    padding: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      padding: theme.spacing(0.25, 1),
    },
    zIndex: 99999,
    '& p': {
      fontSize: fontSizes.medium,
      fontWeight: 700,
      color: colors.white,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: fontSizes.small,
      },
    },
  }))
