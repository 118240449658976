import React from 'react'
import { GameTicker } from '../GameComponents/GameTicker/GameTicker'
import { AutocompleteContainer } from '../Search'
import Navbar from './Navbar'
import { SiteNavbar } from './SiteNavbar/SiteNavbar'
import { AdUnit } from '../Ads/AdUnit'

type NavParams = {
  showPLLNav?: boolean
  showStatsNav?: boolean
  showTicker?: boolean
  showSearch?: boolean
}

export const NavComponent: React.FC<NavParams> = ({
  showPLLNav = true,
  showStatsNav = true,
  showTicker = false,
  showSearch = true,
}) => {
  return (
    <>
      { showPLLNav && <SiteNavbar />}
      { showStatsNav && <Navbar />}
      { showTicker && <GameTicker />}
      { showSearch && <AutocompleteContainer />}
      { showPLLNav && <AdUnit bannerType="bannerMain" />}
    </>
  )
}
