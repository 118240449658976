import React from 'react'
import { EventGQL } from '../../../../Api'
import { logEvent } from '../../../../Utils'
import {
  createDayTicketUrl,
  getPreviewUrl,
} from '../../../../Utils/EventHelpers/eventHelpers'
import { BoxscoreActionPrimary, BoxscoreActionSecondary } from '../styles'

type ActionProps = {
  event: EventGQL
  buttonText: string
}

export const BoxScoreAction: React.FC<React.PropsWithChildren<ActionProps>> = ({
  event,
  buttonText,
}) => {
  const gameUrl = `/games/${event.year}/${event.slugname}`

  return (
    <>
      {event.urlTicket && event.eventStatus === 0 && buttonText === 'Tickets' && (
        <BoxscoreActionPrimary
          href={createDayTicketUrl(event.urlTicket, 'Ticker', event.week)}
          onClick={() =>
            logEvent('boxscore_click', {
              button: 'action_button',
              button_text: buttonText,
              section: 'game_ticker',
            })
          }
        >
          {buttonText}
        </BoxscoreActionPrimary>
      )}

      {event.eventStatus === 1 && buttonText === 'Watch' && (
        <BoxscoreActionPrimary
          href={event.urlStreaming}
          isLive={true}
          onClick={() =>
            logEvent('boxscore_click', {
              button: 'action_button',
              button_text: buttonText,
              section: 'game_ticker',
            })
          }
        >
          {buttonText}
        </BoxscoreActionPrimary>
      )}

      {event.eventStatus === 0 &&
        event.awayTeam &&
        event.homeTeam &&
        buttonText === 'Preview' && (
          <BoxscoreActionSecondary
            href={getPreviewUrl(event?.slugname)}
            onClick={() =>
              logEvent('boxscore_click', {
                button: 'action_button',
                button_text: buttonText,
                section: 'game_ticker',
              })
            }
          >
            {buttonText}
          </BoxscoreActionSecondary>
        )}

      {event.eventStatus >= 1 && buttonText === 'View Stats' && (
        <BoxscoreActionSecondary
          href={gameUrl}
          onClick={() =>
            logEvent('boxscore_click', {
              button: 'action_button',
              button_text: buttonText,
              section: 'game_ticker',
            })
          }
        >
          {buttonText}
        </BoxscoreActionSecondary>
      )}
    </>
  )
}
