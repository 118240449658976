import { MAIN_PLL_HOME } from '../../../Config/League/league.config'

// const TEAM_URL = '/teams'
const PLAYER_URL = `${MAIN_PLL_HOME}/player`

export type RouteNames = 'teams' | 'player'
export type PlayerTabs = 'profile' | 'stats' | 'media'
export type SeasonType = 'regular' | 'post'

export type UrlParams = {
  id?: string
  tab?: PlayerTabs
  firstName?: string
  lastName?: string
  year?: number
  seasonType?: SeasonType
  slugName: string
}

/**
 * Create URLs
 */
export const createUrl = (name: RouteNames, params: UrlParams): string => {

  if (name === 'player') {
    let slug = ''
    if(params.slugName){
      slug = params.slugName
    } else {
      slug = `${params.firstName?.toLowerCase()}-${params.lastName?.toLowerCase()}`
    }
    let url: string
    if (params.tab && params.tab === 'profile') {
      url = `${PLAYER_URL}/${slug}`
    } else {
      url = `${PLAYER_URL}/${slug}/${params.tab}`
    }
    if(!params.year){
      return url
    } else {
      const yr = params.year.toString()
      return `${url}${yr ? `?yr=${yr}` : ''}`
    }
  } else {
    return '/'
  }
}
