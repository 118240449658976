import { styled } from '@mui/system'

type StyleProps = {
  vertMargins?: number
  sideMargins?: number
  noMargin?: boolean
  background?: string
}

export const AdCon = styled('div')<StyleProps>(
  ({ noMargin, vertMargins, sideMargins, background, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: noMargin === true ? 0 : theme.spacing(vertMargins || 1, sideMargins || 0.5),
    width: `100vw - ${theme.spacing((sideMargins || 0.5)*2)}px`,
    background: background || 'none',
    [theme.breakpoints.down('md')]: {
      marginTop: noMargin === true ? 0 : theme.spacing(0.5),
    },
    '& .adsbygoogle': {
      display: 'inline-block',
      width: 728,
      height: 90,
      [theme.breakpoints.down('md')]: {
        width: 300,
        height: 50
      },
    },
    '& .adsbygoogle[data-ad-status="unfilled"]': {
      display: `none !important`,
    },
  })
)
