import { styled } from '@mui/system'
import { fontSizes, colors } from '../../../Constants/consts'

type AccHeaderParams = {
  colorTheme: 'light' | 'dark' | 'gray'
}

export const AccordionCon = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1, 0),
  },
}))

export const AccordionHeaderCon = styled('div')<AccHeaderParams>(
  ({ colorTheme, theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1),
    '& img': {
      width: 60,
      margin: theme.spacing(1),
    },
    '& .textCon': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: theme.spacing(0, 1),
      '& h1': {
        fontSize: fontSizes.semiLarge,
        color:
          colorTheme === 'dark'
            ? colors.white
            : colorTheme === 'gray'
            ? colors.gray.gray80
            : colors.gray.gray90,
        textAlign: 'left',
        fontWeight: 700,
        margin: theme.spacing(0),
      },
      '& p': {
        margin: theme.spacing(0.5, 0),
        fontSize: fontSizes.medium,
        color:
          colorTheme === 'dark'
            ? colors.white
            : colorTheme === 'gray'
            ? colors.gray.gray80
            : colors.gray.gray90,
        fontWeight: 500,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      '& img': {
        width: 35,
        margin: theme.spacing(1),
      },
      '& .textCon': {
        margin: theme.spacing(0.5),
        '& h1': {
          fontSize: fontSizes.medium,
        },
        '& p': {
          fontSize: fontSizes.small,
        },
      },
    },
  })
)
