import { styled } from '@mui/system'
import { panelWidth } from '../../Constants/consts'

type StyleProps = {
  panelOn: 'left' | 'right'
}

export const SidePanelLayout = styled('div')<StyleProps>(
  ({ panelOn, theme }) => ({
    width: '100%',
    display: 'grid',
    columnGap: theme.spacing(2),

    ...(panelOn === 'right'
      ? {
          // panel on right side
          gridTemplateColumns: `1fr minmax(25%,${panelWidth.desktop}px)`,
          gridTemplateAreas: '"left right"',
        }
      : {
          // panel on left side
          gridTemplateColumns: `minmax(25%,${panelWidth.desktop}px) 1fr`,
          gridTemplateAreas: '"right left"',
        }),

    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  })
)

export const SidePanel = styled('div')(
  ({ theme }) => ({
    gridArea: 'right',
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      display: 'unset',
    }
  })
)

export const MainColumn = styled('div')(
  ({ theme }) => ({
    gridArea: 'left',
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > :last-child': {
      flexGrow: 1,
    },
    [theme.breakpoints.down('md')]: {
      display: 'unset',
    },
  })
)

