import React from 'react'
import { EventGQL } from '../../../../Api'
import { BoxScoreInfoCon } from '../styles'
import { BoxScoreAction } from './BoxScoreAction'

type ActionProps = {
  event: EventGQL
  date?: string
}

export const BoxScoreInfo: React.FC<React.PropsWithChildren<ActionProps>> = ({
  event,
  date,
}) => {
  return (
    <BoxScoreInfoCon>
      <div className="weekCol">
        <div className="week">
          {event?.seasonSegment === 'regular'
            ? `Wk ${event?.week} • ${event.location}`
            : `${event?.description} • ${event.location}`}
        </div>
        {date && <div className="dateBoxText">{date}</div>}
      </div>
      <BoxScoreAction event={event} buttonText="Watch" />
      <BoxScoreAction event={event} buttonText="Tickets" />
    </BoxScoreInfoCon>
  )
}
