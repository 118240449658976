import { PlayerData, Team } from '../../Api'
import {
  APP_LINK_BASE_URL,
  MAIN_PLL_URL,
  PLL_IMAGES,
  PLL_LINKS,
} from '../../Config/League/league.config'
import { createDynamicLink } from '../../Routing/utils'

export const APP_LINKS = {
  homeCallout: PLL_LINKS.homepageAppLink,
  scoresCallout: `${APP_LINK_BASE_URL}/web-scores`,
}

export const createProfileDynamicLink = () => {
  let pageId = 'UserProfile'
  let webUrl = 'https://premierlacrosseleague.com/login'
  const params = [
    {
      title: 'st',
      value: 'PLL%20Profile',
    },
    {
      title: 'si',
      value: PLL_IMAGES.pllAppFeaturedImage,
    },
  ]
  return createDynamicLink(params, pageId, webUrl)
}

export const createStandingsDynamicLink = () => {
  let pageId = 'Standings'
  let webUrl = 'https://premierlacrosseleague.com/standings'
  const params = [
    {
      title: 'st',
      value: 'PLL%20Standings',
    },
    {
      title: 'si',
      value: PLL_IMAGES.pllAppFeaturedImage,
    },
  ]
  return createDynamicLink(params, pageId, webUrl)
}

export const createPlayerDynamicLink = (
  player: PlayerData,
  tab: string = 'Profile'
) => {
  let pageId = 'PlayerProfile'
  let sTitle = encodeURIComponent(
    `${player.firstName} ${player.lastName} Profile & Stats`.trim()
  )
  let url = `https://${MAIN_PLL_URL}/player/${player.slug}`
  const params = [
    {
      title: 'slug',
      value: player.slug,
    },
    {
      title: 'tab',
      value: tab,
    },
    {
      title: 'st',
      value: sTitle,
    },
    {
      title: 'si',
      value: player.profileUrl || PLL_IMAGES.pllAppFeaturedImage,
    },
  ]
  return createDynamicLink(params, pageId, url)
}

export const createTeamDynamicLink = (
  team: Team,
  tab: string = 'overview'
) => {
  let pageId = 'TeamProfile'
  let webUrl = `https://premierlacrosseleague.com/teams/${team.fullName}`
  const params = [
    {
      title: 'teamID',
      value: team.officialId,
    },
    {
      title: 'tab',
      value: tab,
    },
    {
      title: 'st',
      value: `${team.fullName} Lacrosse Club ${tab}`,
    },
    {
      title: 'si',
      value: team.urlLogo,
    },
  ]
  return createDynamicLink(params, pageId, webUrl)
}

export const createMatchupPreviewDynamicLink = (eventSlug: string) => {
  let pageId = 'GamePreview'
  let webUrl = `https://premierlacrosseleague.com/matchup/${eventSlug}`
  const params = [
    {
      title: 'eventSlug',
      value: eventSlug,
    },
    {
      title: 'st',
      value: 'PLL%20Game%20Preview',
    },
    {
      title: 'si',
      value: PLL_IMAGES.pllAppFeaturedImage,
    },
    {
      title: "afl",
      value: webUrl,
    }
  ]
  return createDynamicLink(params, pageId, webUrl)
}
