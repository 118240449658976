import { UserFavorites } from "./types"

export const mapFavorites = (user: any): UserFavorites => {
    let favPs: string[] = []
    if (!user?.favPlayers) {
      return {
        topics: user?.topics || [],
        favPlayers: favPs,
        favTeam: user?.favTeam || null,
      }
    }
    if (user.favPlayers.length >= 1) {
      user.favPlayers.map((pl: string) => {
        const cleaned = pl.split(' ').join('-').toLowerCase() // fix previous favorite storage
        if (cleaned.slice(-1) === '-') {
          const newCleaned = cleaned.replace(/.$/, '') // check if dash at end of slug
          favPs.push(newCleaned)
          return
        }
        favPs.push(cleaned)
        return
      })
    } else {
      favPs = []
    }
    return {
      topics: user?.topics || [],
      favPlayers: favPs,
      favTeam: user?.favTeam || null,
    }
  }

  export const formatProfileUrl = (profileUrl: string) => {
    if(!profileUrl) return 
    let containsHttp = profileUrl.includes('https://')
    if(containsHttp) return profileUrl
    return `https://${profileUrl}`
  }
