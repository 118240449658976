export const ADS_PUBLISHER_ID = `1025046`
export const ADS_WEB_ID = `74527`
export const ADSENSE_CLIENT_ID = `ca-pub-3201393185455772`

export type AdOptionTypeObj = {
  [key: string]: {
    slotId: string
    selectorId: string
    type: string
    cssClass: string
    layoutKey?: string
  }
}

export type AdOptionType = {
  slotId: string
  selectorId: string
  type: string
  cssClass: string
  layoutKey?: string
}

export const AD_OPTIONS: AdOptionTypeObj = {
  bannerMain: {
    slotId: '5660628357',
    selectorId: 'first-leaderboard',
    type: 'leaderboard_atf',
    cssClass: 'leaderboard',
  },
  bannerSecond: {
    slotId: '6620887626',
    selectorId: 'second-leaderboard',
    type: 'leaderboard_btf',
    cssClass: 'leaderboard',
  },
  bannerThird: {
    slotId: '5309274567',
    selectorId: 'third-leaderboard',
    type: 'leaderboard_btf',
    cssClass: 'leaderboard',
  },
  bannerFourth: {
    slotId: '9500990382',
    selectorId: 'fourth-leaderboard',
    type: 'leaderboard_btf',
    cssClass: 'leaderboard',
  },
  bannerInContent: {
    slotId: '5756869262',
    selectorId: 'banner-incontent',
    type: 'in_content',
    cssClass: 'leaderboard',
    layoutKey: '-fb+5w+4e-db+86'
  },
  mediumRectangle: {
    slotId: '7164368015',
    selectorId: 'first-med-rect',
    type: 'med_rect_btf',
    cssClass: 'rectangle',
  },
}
