import { NO_USER } from '../firebase/AuthData'
import { FavType } from '../firebase/AuthData'
import { makeAPIRequest, QueryParams } from '../utils/request'
import { getFirAuth } from '../firebase/firebaseConnect'
import { USER_ENDPOINTS } from '../utils/endpoints'
import { FirebaseUser, UserUpdateData } from './types'

export type FavoritesProps = {
  uid: string | null
  email: string | null
  item: string
  favType: FavType
  favorites: string[] | []
  team?: string
}

export type TeamFavoriteProps = {
  uid: string | null
  email: string | null
  team: string
}

export const getOrCreateUser = async (data: FirebaseUser) => {
  const usr = (await getFirAuth())?.currentUser
  let headerParams: QueryParams = {}
  if (!usr || usr.isAnonymous) {
    return Promise.resolve(NO_USER)
  }
  const token = await usr.getIdToken()
  headerParams.Authorization = `Bearer ${token}`
  let body = JSON.stringify(data)
  try {
    const response = await makeAPIRequest(USER_ENDPOINTS.GET_USER, {
      method: 'POST',
      apiVersion: '3',
      headerParams: headerParams,
      body: body,
    })
    if (response?.error) {
      console.error(response.error?.message)
      return data //Use passed in firebase data
    }
    return response?.data
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}

export const updateUser = async (data: UserUpdateData) => {
  const usr = (await getFirAuth())?.currentUser
  if (!usr) return false
  let headerParams: QueryParams = {}
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  let body = JSON.stringify(data)
  try {
    const response = await makeAPIRequest(USER_ENDPOINTS.UPDATE_USER, {
      method: 'PUT',
      apiVersion: '2',
      headerParams: headerParams,
      body: body,
    })
    if (response?.error) {
      console.error(response.error?.message || 'Unknown error')
      return false
    }
    return true
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}
