import { PlayerData } from '../../Api'
import { REGULAR_SEASON_FINAL_WEEKS } from '../../Config/Fantasy/fantasy.config'
import { CURRENT_YEAR } from '../../Config/League/league.config'
import { Season } from '../../Context/SeasonContext/SeasonContext'

export const mergeFantasyPlayers = (
  players: PlayerData[],
  fantasyPlayers: PlayerData[]
): PlayerData[] => {
  let mergedList: PlayerData[] = []
  players.map((player) => {
    const foundPlayer = fantasyPlayers.find(
      (fantasyPlayer) => fantasyPlayer.officialId === player.officialId
    )
    if (!foundPlayer) return
    player.fantasy = foundPlayer.fantasy
    mergedList.push(player)
  })
  return mergedList
}

export const findCurrentWeekByDate = () => {
  const currentTime = Math.floor(Date.now() / 1000)
  const currentWeek = CURRENT_SEASON_WEEKS.find(
    (week) => currentTime >= week.openTime && currentTime <= week.closeTime
  )
  return currentWeek ? currentWeek.week : null
}

/**
 * Determining current week for current year or getting final week for previous years
 */
export const determineWeekForFantasy = (season: Season) => {
  let fantasyWeek = 1
  if (season.year < CURRENT_YEAR) {
    const finalWeekForYear = REGULAR_SEASON_FINAL_WEEKS[season.year]
    fantasyWeek = finalWeekForYear
  } else {
    fantasyWeek = findCurrentWeekByDate() || 1
  }
  return fantasyWeek
}

//Easier way to know when fantasy week starts and ends
export const CURRENT_SEASON_WEEKS = [
  {
    week: 0,
    openTime: 1703793793,
    closeTime: 1714119816,
  },
  {
    week: 1,
    openTime: 1714119816,
    closeTime: 1717484400,
  },
  {
    week: 2,
    openTime: 1717484401,
    closeTime: 1718089200,
  },
  {
    week: 3,
    openTime: 1718089201,
    closeTime: 1718694000,
  },
  {
    week: 4,
    openTime: 1718694001,
    closeTime: 1719903600,
  },
  {
    week: 5,
    openTime: 1719903601,
    closeTime: 1720508400,
  },
  {
    week: 6,
    openTime: 1720508401,
    closeTime: 1721113200,
  },
  {
    week: 7,
    openTime: 1721113201,
    closeTime: 1721718000,
  },
  {
    week: 8,
    openTime: 1721718001,
    closeTime: 1722322800,
  },
  {
    week: 9,
    openTime: 1722322801,
    closeTime: 1722927600,
  },
  {
    week: 10,
    openTime: 1722927601,
    closeTime: 1723532400,
  },
  {
    week: 11,
    openTime: 1723532401,
    closeTime: 1724742000,
  },
]
