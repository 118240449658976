import { styled } from '@mui/system'

export const SearchConStyle = styled('div')(({ theme }) => ({
  display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}))

export const SearchStyle = styled('div')(({ theme }) => ({
  position: 'relative',
    margin: '5px 10px 0 auto',
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      margin: '10px auto',
    },
}))
