import { days } from '../Config/League/league.config'

export const checkLastUserDataPromptShown = () => {
  const localStorage = window.localStorage
  const lastShown = localStorage.getItem('lastShownUserUpdatePrompt')
  if (!lastShown) {
    return true
  }
  const timestamp = parseInt(lastShown)
  const delta = new Date().getTime() - timestamp
  if (delta > 864000000) {
    //10 days in miliseconds
    return true
  }
  return false
}

export const setLastSeenInStorage = (name: string) => {
  const time = new Date().getTime()
  const timeStr = time.toString()
  const localStorage = window.localStorage
  localStorage.setItem(name, timeStr)
}

export const isPastTime = (timestamp: number) => {
  const time = new Date().getTime() / 1000
  const delta = timestamp - time
  if (delta < 0) {
    //Past time
    return true
  }
  return false
}

/**
 *
 * Checking if day has changed, used for preventing action for 1 day
 */
export const isNextDay = (storageId: string) => {
  const localStorage = window.localStorage
  const storedDay = localStorage.getItem(storageId)
  if (!storedDay) {
    return true
  }
  const dayNum = new Date().getDay()
  const day = days[dayNum]
  if (storedDay === day) {
    //Same day, day did not change
    return false
  }
  return true
}

/**
 *
 * Checking if day has changed, used for preventing action for 1 day
 */
export const isBetweenDates = (start: number, end: number) => {
  const now = new Date().getTime() / 1000
  if (now > start && now < end) {
    //In between time range
    return true
  }
  return false
}

/**
 *
 * Checking variable is not null or undefined, used for optional number variable
 */
export const isEmpty = (value: string | number | null | undefined) =>{
  return value == null
}
