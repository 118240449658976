import React from 'react'
import { styled } from '@mui/system'
import { FOOTER_HEIGHT } from './MobileMenuContainer'
import { colors } from '../../Constants/consts'

type PageContainerProps = {
  color?: string
  sidePadding?: number
  useFooter?: boolean
  sponsor?: boolean
  fullScreen?: boolean
}

export const MainPageStyle = styled('div')<PageContainerProps>(
  ({ color, useFooter, sidePadding, fullScreen, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: fullScreen ? '100vh' : 'auto',
    padding: theme.spacing(
      0,
      sidePadding ? sidePadding : 6,
      8,
      sidePadding ? sidePadding : 6
    ),
    background: color,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4, 4, 4),
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 4, 0),
      marginBottom: useFooter ? FOOTER_HEIGHT : 0,
      width: '100%',
    },
  })
)

export const PageContainer: React.FC<React.PropsWithChildren<PageContainerProps>> = ({
  color = colors.darkBackground,
  sidePadding,
  useFooter,
  fullScreen,
  children,
}) => {
  return (
    <MainPageStyle color={color} useFooter={useFooter} sidePadding={sidePadding} fullScreen={fullScreen}>
      {children}
    </MainPageStyle>
  )
}
