import React, { useState } from 'react'
import { NavActionCon, NavbarCon } from './styles'
import { SubMenu } from './SubMenu'
import { NavToggle } from '../NavToggle/NavToggle'
import { NavItems } from './NavItems'
import PLL_MAIN_LOGO from '../../../images/PLL_navbar_logo.webp'
import {
  MAIN_PLL_HOME,
  PLL_LINKS,
  TEAM_MENU,
  MORE_MENU,
} from '../../../Config/League/league.config'
import { Img } from '../../Images/Img'
import { AccountCircleOutlined } from '../../LazyComponents/lazyIcons'

export const SiteNavbar: React.FC = () => {
  const [open, setOpen] = useState(false)
  const getMainButton = () => {
    return (
      <NavActionCon href={PLL_LINKS.schedule}>
        <p>Tickets</p>
      </NavActionCon>
    )
  }

  return (
    <NavbarCon>
      <div className="brand">
        <a href={MAIN_PLL_HOME}>
          <Img src={PLL_MAIN_LOGO} alt="PLL Navbar logo" />
        </a>
      </div>
      <nav className="navMain">
        <div className="navMobile">
          <div className="toggleContainer">
            <NavToggle open={open} setOpen={setOpen} />
          </div>
          <div className="brand">
            <a href={MAIN_PLL_HOME}>
              <Img src={PLL_MAIN_LOGO} alt="PLL Main Logo" />
            </a>
          </div>
          <NavItems open={open} />
        </div>
        <ul className="navList">
          <SubMenu menuText="Teams ▾" options={TEAM_MENU}></SubMenu>
          <li>
            <a href={PLL_LINKS.schedule}>Schedule</a>
          </li>
          <li>
            <a href={PLL_LINKS.standings}>Standings</a>
          </li>
          <li>
            <a href={PLL_LINKS.fantasy}>Fantasy</a>
          </li>
          <li>
            <a href={PLL_LINKS.academy}>PLL Play</a>
          </li>
          <li>
            <a href={PLL_LINKS.whereToWatch}>Where To Watch</a>
          </li>
          <SubMenu menuText="More ▾" options={MORE_MENU}></SubMenu>
        </ul>
      </nav>
      <div className="rightCol">
        {getMainButton()}
        <a className="navProfile" href={PLL_LINKS.login}>
          <AccountCircleOutlined style={{ color: '#fff', fontSize: 40 }} />
        </a>
      </div>
    </NavbarCon>
  )
}
