import {
  // CURRENT_YEAR,
  MAIN_PLL_URL,
  PLL_IMAGE_URL,
  Team_LOGO_URL,
} from '../../../Config/League/league.config'
import { getFullTeamName, getGeneralTeamIc } from '../../../Utils'

export const getTeamIc = (teamName: string, year: number) => {
  if (!teamName) {
    return getGeneralTeamIc()
  }
  if (teamName.length <= 3) {
    //Team code
    const t = getFullTeamName(teamName)
    if (!t) return getGeneralTeamIc()
    teamName = t
  }
  const lowerCase = teamName.toLowerCase()
  switch (lowerCase) {
    case 'baptiste':
    case 'farrell':
      return `${Team_LOGO_URL}/2022/Logo/team-${lowerCase}.png`
    case 'adversaries':
    case 'defenders':
      return `${PLL_IMAGE_URL}/2021/07/${lowerCase}.jpg`
    case 'veterans':
    case 'rising stars':
      return `${Team_LOGO_URL}/2023/Logo/2023_ASG_${lowerCase}.webp`
    default:
      return year < 2024 || lowerCase === 'chrome'
        ? `https://${MAIN_PLL_URL}/${lowerCase}_primary_fullcolor`
        : `${Team_LOGO_URL}/2024/Logo/2024_${lowerCase}_primary_color.png`
  }
}
