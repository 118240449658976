export enum GAMIFICATION_ENGAGEMENTS {
    LOG_IN = 'logged_in',
    ARTICLE_READ = 'ARTICLE_READ',
    ARTICLE_SHARE = 'ARTICLE_SHARE',
    VIDEO_WATCH = 'VIDEO_WATCH',
    VIDEO_SHARE = 'VIDEO_SHARE',
}

export const gamificationEngagements = {
    LOG_IN: GAMIFICATION_ENGAGEMENTS.LOG_IN,
    ARTICLE_READ: GAMIFICATION_ENGAGEMENTS.ARTICLE_READ,
    ARTICLE_SHARE: GAMIFICATION_ENGAGEMENTS.ARTICLE_SHARE,
    VIDEO_WATCH: GAMIFICATION_ENGAGEMENTS.VIDEO_WATCH,
    VIDEO_SHARE: GAMIFICATION_ENGAGEMENTS.VIDEO_SHARE,
} 