import React, { useContext, useEffect, useState } from 'react'
import { Overlay } from '../Layout'
import { ModalFrame } from './styles'
import { SignInCon } from './SignInCon'
import { AuthContext } from '../../Api/firebase'
import { SignInMoreData } from './SignInMoreData'

type SignInModalProps = {
  onClose: () => void
  signInWithSocial: (provider: string) => void
  signInWithEmLink: (redirectTo: string, email: string) => Promise<boolean>
  extraFields?: string[]
  allowSkip?: boolean
}

export const SignInModal: React.FC<React.PropsWithChildren<SignInModalProps>> = ({
  onClose,
  signInWithSocial,
  signInWithEmLink,
  extraFields = [],
}) => {
  const { authLoading, isLoggedIn, getMissingUserFields } = useContext(AuthContext)!
  const [flowStep, setFlowStep] = useState<number>(1)

  useEffect(() => {
    if (authLoading || !isLoggedIn) return
    if (extraFields.length < 1) return onClose()
    //Check for missing profile info
    let missingFields = getMissingUserFields(extraFields)
    if (missingFields.length < 1) return onClose()
    setFlowStep(2)
  }, [authLoading])

  return (
    <Overlay type="slideup" orientation="bottom" onClick={onClose}>
      <ModalFrame>
        {flowStep === 1 && (
          <SignInCon
            onClose={onClose}
            signInWithSocial={signInWithSocial}
            signInWithEmLink={signInWithEmLink}
            uiTheme="light"
          />
        )}
        {flowStep === 2 && (
          <SignInMoreData
            extraFields={extraFields}
            allowSkip={true}
            onFinishedSubmit={onClose}
            uiTheme="light"
          />
        )}
      </ModalFrame>
    </Overlay>
  )
}
