import React, { useEffect, useRef } from 'react'
import { ADSENSE_CLIENT_ID, AD_OPTIONS } from '../../Config/Ads/ads.config'
import { AdCon } from './styles'
import { BottomCon } from '../Layout/Overlays/styles'

type AdProps = {
  bannerType: string
  bottomFixed?: boolean
  inContent?: boolean
}

export const AdUnit: React.FC<AdProps> = ({
  bannerType,
  bottomFixed = false,
  inContent = false,
}) => {
  const banner = AD_OPTIONS[bannerType]
  const isDevEnv = process.env.NODE_ENV === 'development'

  const adRef = useRef<HTMLModElement>(null)
  const adInitialized = useRef(false)

  useEffect(() => {
    if (isDevEnv) return
    if (!window.adsbygoogle || !window.adsbygoogle.push) return

    if (adInitialized.current) return
    try {
        window.adsbygoogle.push({})
        adInitialized.current = true
    } catch (e) {
      console.error('Ad error:', e)
    }
  }, [isDevEnv])

  if (isDevEnv) return null

  const AdElement = (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client={ADSENSE_CLIENT_ID}
      data-ad-slot={banner.slotId}
      {...(inContent && {
        'data-ad-format': 'fluid',
        'data-ad-layout-key': banner.layoutKey,
      })}
      ref={adRef}
    ></ins>
  )

  if (bottomFixed && banner) {
    return (
      <BottomCon>
        <AdCon vertMargins={1} sideMargins={0.5}>
          {AdElement}
        </AdCon>
      </BottomCon>
    )
  }
  else {
    return (
      <>
        {banner && (
          <AdCon vertMargins={1} sideMargins={0.5}>
            {AdElement}
          </AdCon>
        )}
      </>
    )
  }
}
