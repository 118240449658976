import { StandingsCol, TeamStanding } from '../../Api'

export const shouldShowTies = (teams: TeamStanding[]) => {
  //Check if a team has a tie or csTie
  let teamWithTie = teams.find(
    (it) => (it.ties && it.ties > 0) || (it.csTies && it.csTies > 0)
  )
  return teamWithTie ? true : false
}

export const standingsCols: StandingsCol[] = [
  { title: 'W', tooltip: 'Wins', value: 'wins' },
  { title: 'L', tooltip: 'Losses', value: 'losses' },
  { title: 'T', tooltip: 'Ties', value: 'ties' },
  { title: 'S', tooltip: 'Scores', value: 'scores' },
  { title: 'SA', tooltip: 'Scores Against', value: 'scoresAgainst' },
  { title: 'SD', tooltip: 'Score Differential', value: 'scoreDiff' },
]

export const showTiesCol = (title: string, showTies: boolean) => {
  return title !== 'T' || (title === 'T' && showTies)
}
