import { logCustomEvent, getUser } from '@braze/web-sdk'


export const logBrazeEvent = (name: string, params?: any | undefined) => {
    try {
        logCustomEvent(name, params)
    } catch(e){
        console.log(e)
    }
}

export const setBrazeAttribute = (attributeName: string, property: any) => {
    try {
    let brazeUser = getUser()
    if(brazeUser){
        brazeUser.setCustomUserAttribute(attributeName, property)
    }
    } catch(e){
        console.log(e)
    }

}