export function PLLSearchResult({ hit, components }: { hit: any; components: any }) {
  return (
    <a href={hit.hyperlink} className="aa-ItemLink">
      <div className="aa-ItemContent">
        {hit?.image && (
          <img
            src={hit.image}
            style={{ width: 40, height: 40, borderRadius: 10 }}
            alt={`Search for ${hit?.title}`}
          />
        )}
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="title" />
        </div>
      </div>
    </a>
  )
}

export function PLLContentSearchResult({
  hit,
  components,
}: {
  hit: any
  components: any
}) {
  return (
    <a href={hit.hyperlink} className="aa-ItemLink">
      <div className="aa-ItemContent">
        {hit?.image && (
          <img
            src={hit.image}
            style={{ width: 60, height: 40, borderRadius: 10 }}
            alt={`Search for ${hit?.title}`}
          />
        )}
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="title" />
        </div>
      </div>
    </a>
  )
}
