/* eslint-disable array-callback-return */
import {
  Team,
  TeamGroup,
  TeamPlayerGroups,
  TeamStats,
} from '../../Api/teams/teams.types'
import { Season } from '../../Context/SeasonContext/SeasonContext'
import { ConvertStatValue } from '../formatting'

type PackedMap = { [key: string]: [string, string, string] }

type StatListType = {
  season: string[]
  game: string[]
}
export type StatTypes = keyof StatListType
export type StatMapType = {
  name: string
  longName: string
  field: string
}
export type StatGroup = {
  name: string
  sortBy: string
}

// Stat mapping All Stats
// [short name, long name, field name]
export const teamStatFields: PackedMap = {
  W: ['W', 'Wins', 'teamWins'],
  L: ['L', 'Losses', 'teamLosses'],
  S: ['S', 'Scores', 'scores'],
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
  A: ['A', 'Assists', 'assists'],
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  '2PtSh': ['2Pt Sh', '2Pt Shots', 'twoPointShots'],
  '2PtSh%': ['2Pt Sh%', '2Pt Shot %', 'twoPointShotPct'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  FOW: ['FOW', 'Faceoffs Won', 'faceoffsWon'],
  FOL: ['FOL', 'Faceoffs Lost', 'faceoffsLost'],
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
  SA: ['SA', 'Scores Against', 'scoresAgainst'],
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pim'],
  'PP%': ['PP%', 'Power Play Percentage', 'powerPlayPct'],
  PP: ['PP', 'Power Play Goals', 'powerPlayGoals'],
  PPSh: ['PPSh', 'Power Play Shots', 'powerPlayShots'],
  '2PtGA': ['2Pt GA', '2Pt Goals Against', 'twoPointGoalsAgainst'],
  'PK%': ['PK%', 'Penalty Kill Percentage', 'manDownPct'],
  PK: ['PK', 'Penalty Kills', 'timesShortHanded'],
  TCH: ['TCH', 'Touches', 'touches'],
  PAS: ['PAS', 'Total Passes', 'totalPasses'],
  POSS: ['POSS', 'Time Of Possession', 'timeInPossesion'],
  'POSS%': ['POSS%', 'Time Of Possession %', 'timeInPossesionPct']
}

// [short name, long name, field name]
export const teamSeasonStatFields: PackedMap = {
  S: ['S', 'Scores', 'scores'],
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
  A: ['A', 'Assists', 'assists'],
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  '2PtSh': ['2Pt Sh', '2Pt Shots', 'twoPointShots'],
  '2PtSh%': ['2Pt Sh%', '2Pt Shot %', 'twoPointShotPct'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  FOW: ['FOW', 'Faceoffs Won', 'faceoffsWon'],
  FOL: ['FOL', 'Faceoffs Lost', 'faceoffsLost'],
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
  SA: ['SA', 'Scores Against', 'scoresAgainst'],
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pim'],
  'PP%': ['PP%', 'Power Play Percentage', 'powerPlayPct'],
  PP: ['PP', 'Power Play Goals', 'powerPlayGoals'],
  PPSh: ['PPSh', 'Power Play Shots', 'powerPlayShots'],
  '2PtGA': ['2Pt GA', '2Pt Goals Against', 'twoPointGoalsAgainst'],
  'PK%': ['PK%', 'Penalty Kill Percentage', 'manDownPct'],
  PK: ['PK', 'Penalty Kills', 'timesShortHanded'],
  // TCH: ['TCH', 'Touches', 'touches'],
  PAS: ['PAS', 'Total Passes', 'totalPasses']
}

//Team game without wins and losses, saa
export const gameTeamStatFields: PackedMap = {
  S: ['S', 'Scores', 'scores'],
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
  A: ['A', 'Assists', 'assists'],
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  '2PtSh': ['2Pt Sh', '2Pt Shots', 'twoPointShots'],
  '2PtSh%': ['2Pt Sh%', '2Pt Shot %', 'twoPointShotPct'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  FOW: ['FOW', 'Faceoffs Won', 'faceoffsWon'],
  FOL: ['FOL', 'Faceoffs Lost', 'faceoffsLost'],
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
  SA: ['SA', 'Scores Against', 'scoresAgainst'],
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pim'],
  'PP%': ['PP%', 'Power Play Percentage', 'powerPlayPct'],
  PP: ['PP', 'Power Play Goals', 'powerPlayGoals'],
  PPSh: ['PPSh', 'Power Play Shots', 'powerPlayShots'],
  '2PtGA': ['2Pt GA', '2Pt Goals Against', 'twoPointGoalsAgainst'],
  'PK%': ['PK%', 'Penalty Kill Percentage', 'manDownPct'],
  PK: ['PK', 'Penalty Kills', 'timesShortHanded'],
  TCH: ['TCH', 'Touches', 'touches'],
  PAS: ['PAS', 'Total Passes', 'totalPasses']
}

// Stat mapping
// [short name, long name, field name]
export const offensiveTableHeaders: PackedMap = {
  // GP: ['GP', 'Games Played', 'gamesPlayed'],
  S: ['S', 'Scores', 'scores'],
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
  A: ['A', 'Assists', 'assists'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
}
// FOR POSTIONS: A and M
const offense: StatListType = {
  season: ['GP', 'P', '1G', '2G', 'A', 'SH%'],
  game: ['P', '1G', '2G', 'A', 'SH%', 'TO'],
}

export const teamDefenseTableHeaders: PackedMap = {
  // GP: ['GP', 'Games Played', 'gamesPlayed'],
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  SA: ['SA', 'Scores Against', 'scoresAgainst'],
}
/// SSDM, LSM and D
const defense: StatListType = {
  season: ['GP', 'CT', 'GB', 'P'],
  game: ['CT', 'GB', 'P'],
}

export const teamGoalieTableHeaders: PackedMap = {
  // GP: ['GP', 'Games Played', 'gamesPlayed'],
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
}
/// SSDM, LSM and D
const goalie: StatListType = {
  season: ['GP', 'Sv', 'Sv%'],
  game: ['Sv', 'Sv%', 'GA', '2PTGA'],
}

export const teamFaceoffTableHeaders: PackedMap = {
  // GP: ['GP', 'Games Played', 'gamesPlayed'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  FO: ['FO', 'Faceoffs', 'foRecord'],
}
// FOR POSTIONS: FO
const faceoff: StatListType = {
  season: ['GP', 'FO', 'FO%', 'GB', 'P'],
  game: ['FO', 'FO%', 'GB', 'P', 'A'],
}

export const teamSpecialTeamsOffensiveTableHeaders: PackedMap = {
  // GP: ['GP', 'Games Played', 'gamesPlayed'],
  'PP%': ['PP%', 'Power Play Percentage', 'powerPlayPct'],
  PowG: ['PowG', 'Power Play Goals', 'powerPlayGoals'],
  PPSh: ['PPSh', 'Power Play Shots', 'powerPlayShots'],
}
export const teamManDownTableHeaders: PackedMap = {
  // GP: ['GP', 'Games Played', 'gamesPlayed'],
  'PK%': ['PK%', 'Penalty Kill Percentage', 'manDownPct'],
  PK: ['PK', 'Penalty Kills', 'timesShortHanded'],
  PIM: ['PIM', 'Penalty Minutes', 'pim'],
}

/**
 * Get stats mapping for player position
 */
export const getMapForTeamGroup = (group: TeamGroup): PackedMap => {
  switch (group.toLowerCase()) {
    case 'offensive':
      return offensiveTableHeaders
    case 'defensive':
      return teamDefenseTableHeaders
    case 'faceoff':
      return teamFaceoffTableHeaders
    case 'goaltending':
      return teamGoalieTableHeaders
    case 'power play':
      return teamSpecialTeamsOffensiveTableHeaders
    case 'penalty kill':
      return teamManDownTableHeaders
    case 'game stats':
      return gameTeamStatFields
    case 'season stats':
      return teamSeasonStatFields
    case 'all':
      return teamStatFields
    default:
      throw new Error(`unknown position $ position}`)
  }
}

/**
 * Getting headers
 */
export function getTeamStatList(group: TeamGroup, statType: StatTypes): StatMapType[] {
  const maps = getMapForTeamGroup(group)[statType]
  const result: StatMapType[] = []
  maps.map((key) => {
    const row = teamStatFields[key]
    if (row) {
      result.push({
        name: row[0],
        longName: row[1],
        field: row[2],
      })
    }
  })
  return result
}

/**
 * Get stats mapping for player position
 */
export const getMapForTeamPlayersGroup = (group: TeamPlayerGroups): StatListType => {
  switch (group.toLowerCase()) {
    case 'offensive':
      return offense
    case 'defensive':
      return defense
    case 'faceoff':
      return faceoff
    case 'goalie':
      return goalie
    case 'rookie':
      return offense
    default:
      throw new Error(`unknown team player group $ position}`)
  }
}

export function getTeamPlayersStatList(
  position: TeamPlayerGroups,
  statType: StatTypes
): StatMapType[] {
  const maps = getMapForTeamPlayersGroup(position)[statType]
  const result: StatMapType[] = []
  maps.map((key) => {
    const row = teamStatFields[key]
    if (row) {
      result.push({
        name: row[0],
        longName: row[1],
        field: row[2],
      })
    }
  })
  return result
}

export type FilteredResult = StatMapType & { value: number | string | null | undefined }

/**
 * Stat player groups and the stat to sort by
 */
export const teamPlayersStatGroups: StatGroup[] = [
  { name: 'Attack', sortBy: 'points' },
  { name: 'Midfield', sortBy: 'points' },
  { name: 'Long Stick Midfield', sortBy: 'causeTurnovers' },
  { name: 'Defense', sortBy: 'causeTurnovers' },
  { name: 'Faceoff', sortBy: 'faceoffPct' },
  { name: 'Goalie', sortBy: 'saves' },
  { name: 'Rookies', sortBy: 'points' },
]

/**
 * Stat player groups and the stat to sort by
 */
export const teamsStatsGroups: StatGroup[] = [
  { name: 'Offensive', sortBy: 'scores' },
  { name: 'Defensive', sortBy: 'saa' },
  { name: 'Faceoff', sortBy: 'faceoffPct' },
  { name: 'Goaltending', sortBy: 'savePct' },
  { name: 'Power Play', sortBy: 'powerPlayPct' },
  { name: 'Penalty Kill', sortBy: 'manDownPct' },
]

/**
 * Stat player groups and the stat to sort by
 */
export const teamStatGroups: StatGroup[] = [
  { name: 'Offensive', sortBy: 'scores' },
  { name: 'Defensive', sortBy: 'saa' },
  { name: 'Faceoff', sortBy: 'faceoffPct' },
  { name: 'Goaltending', sortBy: 'savePct' },
  { name: 'Power Play', sortBy: 'powerPlayPct' },
  { name: 'Penalty Kill', sortBy: 'manDownPct' },
  { name: 'Game Stats', sortBy: 'scores' },
  { name: 'Season Stats', sortBy: 'scores' },
  { name: 'All', sortBy: 'scores' },
]

/**
 * Returning list of stats to use, used in getting player leaders or game breakdowns
 */
export const getStatsToUseList = (
  headerType: 'ByPlayerPosition' | 'ByTeamStats' | 'GamePlayers'
): StatGroup[] => {
  switch (headerType) {
    case 'ByPlayerPosition':
      return teamPlayersStatGroups

    case 'ByTeamStats':
      return teamsStatsGroups
    default:
      return []
  }
}

/**
 * Returning formatted stats with checks
 */
export const renderTeamStat = (
  team: Team,
  v: [string, string, string],
  seasonSelect: Season
) => {
  if (seasonSelect.seasonType === 'champseries') {
    if (v[0] === 'PK' || v[0] === 'FO') {
      let merged = mergeStats(team, v, 'champseries')
      return merged
    }
    if (!team.champSeries?.stats || !(v[2] in team.champSeries?.stats)) return '-'
    let stat = team.champSeries?.stats
      ? team.champSeries?.stats[v[2] as keyof TeamStats]
      : 0

    return ConvertStatValue(stat, v[2])
  } else if (seasonSelect.seasonType === 'post') {
    if (v[0] === 'PK' || v[0] === 'FO') {
      let merged = mergeStats(team, v, 'post')
      return merged
    }
    if (!team.postStats || !(v[2] in team.postStats)) return null
    let stat = team.postStats[v[2] as keyof TeamStats] || 0
    return ConvertStatValue(stat, v[2])
  } else {
    if (v[0] === 'PK' || v[0] === 'FO') {
      let merged = mergeStats(team, v, 'regular')
      return merged
    }
    if (!team.stats || !(v[2] in team.stats)) return null
    let stat = team.stats[v[2] as keyof TeamStats] || 0
    return ConvertStatValue(stat, v[2])
  }
}

/**
 * Returning merged stats as one string for tables
 */
export const mergeStats = (team: Team, v: [string, string, string], segment: string) => {
  switch (segment) {
    case 'champseries':
      //PP - Power Plays
      if (!team.champSeries?.stats) return ''
      if (v[0] === 'PP' && team.champSeries?.stats) {
        return `${team.champSeries?.stats?.powerPlayGoals || 0}
        - ${team.champSeries?.stats?.timesManUp || 0}`
      }
      //PK - penalty kills
      if (v[0] === 'PK' && team.champSeries?.stats) {
        return `${
          (team.champSeries?.stats?.timesShortHanded || 0) -
          (team.champSeries?.stats?.powerPlayGoalsAgainst || 0)
        }
        - ${team.champSeries?.stats?.timesShortHanded || 0}`
      }
      //Faceoff string
      if (v[0] === 'FO') {
        return `${team.champSeries?.stats?.faceoffsWon || 0} -
          ${team.champSeries?.stats?.faceoffs || 0}`
      }
      return ''

    case 'post':
      if (!team.postStats) return ''
      //PP - Power Plays
      if (!team.postStats) return ''
      if (v[0] === 'PP' && team.postStats) {
        return `${team.postStats.powerPlayGoals || 0}
                    - ${team.postStats.timesManUp || 0}`
      }
      //PK - penalty kills
      if (v[0] === 'PK' && team.postStats) {
        return `${
          (team.postStats.timesShortHanded || 0) -
          (team.postStats.powerPlayGoalsAgainst || 0)
        }
          - ${team.postStats.timesShortHanded || 0}`
      }
      //Faceoff string
      if (v[0] === 'FO') {
        return `${team.postStats.faceoffsWon || 0} - ${team.postStats.faceoffs || 0}`
      }
      return ''

    case 'regular':
      if (!team.stats) return ''
      //PP - Power Plays
      if (!team.stats) return ''
      if (v[0] === 'PP' && team.stats) {
        return `${team.stats.powerPlayGoals || 0}
                                      - ${team.stats.timesManUp || 0}`
      }
      //PK - penalty kills
      if (v[0] === 'PK' && team.stats) {
        return `${
          (team.stats.timesShortHanded || 0) - (team.stats.powerPlayGoalsAgainst || 0)
        }
          - ${team.stats.timesShortHanded || 0}`
      }
      //Faceoff string
      if (v[0] === 'FO') {
        return `${team.stats.faceoffsWon || 0} - ${team.stats.faceoffs || 0}`
      }
      return ''

    default:
      return ''
  }
}
