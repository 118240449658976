import { styled } from '@mui/system'
import { colors } from '../../Constants/consts'

type ButtonStyleProps = {
    buttonType: 'primary' | 'secondary' | 'outlined'
    disabled?: boolean
}

export const PrimaryBtn = styled('button')<ButtonStyleProps>(({ theme, disabled = false }) => ({
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: theme.spacing(1),
    border: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: colors.black,
    backgroundColor: disabled ? colors.gray.gray20 : colors.yellow,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: disabled ? colors.gray.gray20 : colors.blue,
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(0.5),
    },

  }))

  export const SecondaryBtn = styled('button')<ButtonStyleProps>(({ theme, disabled = false }) => ({
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: theme.spacing(1),
    border: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: colors.black,
    backgroundColor: disabled ? colors.gray.gray20 : colors.lightBlue,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.blue,
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(0.5),
    },

  }))

  export const OutlinedBtn = styled('button')<ButtonStyleProps>(({ theme, disabled = false }) => ({
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: theme.spacing(1),
    border: `solid 1px ${colors.text}`,
    fontSize: '1rem',
    fontWeight: 'bold',
    color: colors.text,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(0.5),
    },
  }))

  type ShareStyleProps = {
    size: string
    border: boolean
    circular?: boolean
    background?: string
    sTheme?: 'light' | 'dark'
  }
  
  export const ShareBtnCon = styled('div')<ShareStyleProps>(
    ({ sTheme, size, border, circular, background, theme }) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(1, 2),
      cursor: 'pointer',
      border: border ? `1px solid ${colors.lightestGrey}` : 'none',
      borderRadius: circular ? '50%' : 12,
      padding: theme.spacing(1, 0.5, 0),
      backgroundColor: background ? background : sTheme === 'dark' ? colors.darkBackground : colors.white,
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0.5, 1),
      },
      '& .icon': {
        width: size === 'lg' ? 30 : 20,
        color: sTheme === 'dark' ? colors.white : colors.text,
        marginTop: 0,
        marginBottom: 7,
        [theme.breakpoints.down('sm')]: {
          marginBottom: 5,
        },
      },
      '& .label': {
        color: sTheme === 'dark' ? colors.white : colors.text,
        margin: theme.spacing(0.25, 1.5),
        fontSize: '0.8rem',
        fontWeight: 600,
        maxWidth: 100,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.6rem',
          margin: theme.spacing(0.25, 1),
        },
      },
    })
  )