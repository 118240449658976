import { styled } from '@mui/system'

export const FOOTER_HEIGHT = 70

export const MobileMenuContainer = styled('footer')(
  ({ theme }) => ({
    display: 'none',
    position: 'fixed',
    left: 0,
    right: 0,
    height: FOOTER_HEIGHT,
    bottom: 0,
    zIndex: 1,
    paddingTop: 15,
    margin: 0,
    background: '#fff',
    boxShadow: '0 -5px 5px rgba(0,0,0,0.2)',
    justifyContent: 'center',
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  })
)
