export const USER_ENDPOINTS = {
    GET_USER: 'get-create-user',
    UPDATE_USER: 'user',
}

export const API_ENDPOINTS = {
    SEASON_EVENTS: 'events',
    GET_FEED: 'home-feed',
    GET_FEED_BY_ID: 'feed-by-id',
    GET_FEED_BY_TAG: 'feed-by-tag',
    GET_GAME_HIGHLIGHTS: 'game-highlights'
}

export const GAMIFCATION_ENDPOINTS = {
    GET_CONFIG: 'configuration',
    ENGAGEMENT: `user-engagement`,
}