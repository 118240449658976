import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import DefaultPage from '../../Pages/DefaultPage/DefaultPage'

const StatsProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.StatsProvider,
  }))
)

export const StatsContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<DefaultPage />}>
      <StatsProvider>
        <Outlet />
      </StatsProvider>
    </Suspense>
  )
}
