import React, { useContext, useState, useEffect } from 'react'
import { StatsContext } from '../../../Context'
import { EventBox } from './EventBox/EventBox'
import { CardSwiper } from '../../CardSwiper'
import { setTickerStart, showTicker } from '../../../Utils'
import type { EventGQL } from '../../../Api'

export const GameTicker: React.FC = () => {
  const { events, isEventsLoading } = useContext(StatsContext)!
  const [show, setShow] = useState<boolean>(false)
  const [startingGame, setStartingGame] = useState<string>('0')

  useEffect(() => {
    if (!showTicker(window.location.href)) return
    if (!isEventsLoading) {
      if (events.length < 1) {
        return
      } else {
        setStartingGame(`${setTickerStart(events)}`)
        setShow(true)
        return
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsLoading])

  useEffect(() => {
    if (!showTicker(window.location.href)) {
      setShow(false)
      return
    }
    setShow(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href])

  if (isEventsLoading || !show) {
    return <div />
  }

  return (
    <>
      {events.length > 1 && (
        <CardSwiper
          items={events.map((ev: EventGQL) => (
            <EventBox key={ev.id} eventId={ev.slugname} />
          ))}
          cardSize="ticker"
          idx={startingGame}
          showPagination
          desktopWidth={'20vw'}
          tabletWidth={'25vw'}
          mobileWidth={'25vw'}
        />
      )}
    </>
  )
}
