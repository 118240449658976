import React, { useState, useEffect } from 'react'
import { AuthCon, CancelRow, LoginIconRow } from './styles'
import { EmailSuccessSnackbar } from '../Snackbar/emailSuccess'
import { logEvent } from '../../Utils'
import { EmailSignIn } from './EmailSignin'
import { Desc } from '../Typography/Text.styles'
import { PhoneSignIn } from './PhoneSignin'
import { ANALYTICS_TAGS } from '../../Constants/analytics'
import { GoogleIcon } from '../LazyComponents'

type SignInConProps = {
  onClose: () => void
  signInWithSocial: (provider: string) => void
  signInWithEmLink: (redirectTo: string, email: string) => Promise<boolean>
  uiTheme: 'light' | 'dark'
}

export const SignInCon: React.FC<React.PropsWithChildren<SignInConProps>> = ({
  onClose,
  signInWithSocial,
  signInWithEmLink,
  uiTheme = 'dark',
}) => {
  const [loginText, setLoginText] = useState<string | null>(null)
  const [inputShown, setInputShown] = useState<string>('email')
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [loginInput, setLoginInput] = useState<string>('')

  useEffect(() => {
    const loginTextStorage = sessionStorage.getItem('login_text')
    if (loginTextStorage) {
      setLoginText(loginTextStorage)
      sessionStorage.removeItem('login_text')
    }
  }, [])

  const handleInputChange = (newVal: string) => {
    if (!newVal || newVal.length < 1) {
      setLoginInput('')
      setInputShown('email')
      return
    }
    if (newVal.includes('@')) {
      newVal = newVal.toLowerCase()
      setInputShown('email')
    } else {
      let itemWithoutSpecialChars = newVal.replace(/[-()]/g, '')
      if (
        itemWithoutSpecialChars.length >= 3 &&
        !isNaN(Number(itemWithoutSpecialChars))
      ) {
        setInputShown('phone')
      }
    }
    setLoginInput(newVal)
    return newVal
  }

  return (
    <AuthCon>
      {loginText && <h3>{loginText}</h3>}
      {inputShown === 'email' && (
        <EmailSignIn
          signInWithEmLink={signInWithEmLink}
          loginInput={loginInput}
          handleInputChange={handleInputChange}
          uiTheme={uiTheme}
        />
      )}
      {inputShown === 'phone' && (
        <PhoneSignIn
          uiTheme={uiTheme}
          loginInput={loginInput}
          handleInputChange={handleInputChange}
        />
      )}
      <Desc justify="center">Or Connect with:</Desc>
      <LoginIconRow>
        <div
          className="iconCon"
          onClick={() => {
            signInWithSocial('google')
            logEvent(ANALYTICS_TAGS.google_login_click, {
              path: window.location.href,
            })
          }}
        >
          <GoogleIcon className="icImage" />
        </div>
      </LoginIconRow>
      <CancelRow>
        <button className="cancelBtn" onClick={onClose}>
          Cancel login
        </button>
      </CancelRow>
      {showSnackbar && (
        <EmailSuccessSnackbar
          text={'Email link sent. Check your email'}
          color="green"
          show={showSnackbar}
        />
      )}
    </AuthCon>
  )
}
