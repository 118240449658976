//
// firebase config
// https://firebase.google.com/docs/web/setup
//
const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || ''
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID || ''
const SENDER_ID = process.env.REACT_APP_SENDER_ID || ''
const APP_ID = process.env.REACT_APP_ID || ''
const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID || ''
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || ''
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''

export const FIREBASE_CONFIG = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: `https://${PROJECT_ID}.firebaseio.com`,
  projectId: PROJECT_ID,
  storageBucket: `${PROJECT_ID}.appspot.com`,
  messagingSenderId: SENDER_ID,
  appId: APP_ID,
  measurementId: `G-${MEASUREMENT_ID}`,
}
export const USE_FIREBASE = API_KEY.length > 0