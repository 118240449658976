import { callGraphQL } from '../utils/request'
import { fetchAllTeams, standingsQuery, teamAllDataQuery } from './teams.queries'
import type {
  TeamInfoResponse,
  Team,
  AllTeamsResponse,
  StandingsRes,
} from './teams.types'

export async function getAllTeams(
  year: number,
  sortBy: string = 'points'
): Promise<Team[]> {
  const data = await callGraphQL<AllTeamsResponse>(fetchAllTeams, {
    year,
    sortBy,
    includeChampSeries: year > 2022,
  })
  if (!data?.allTeams || data.allTeams.length < 1) return []
  return data.allTeams.filter((tm) => tm.officialId !== 'ZPP')
}

export async function getSingleTeamInfoGQL(
  teamId: string,
  year: number,
  statsYear: number,
  eventsYear: number,
): Promise<Team> {
  const data = await callGraphQL<TeamInfoResponse>(teamAllDataQuery, {
    id: teamId,
    year: year,
    statsYear: statsYear,
    eventsYear: eventsYear,
    includeChampSeries: year > 2022
  })
  return data.team
}

export async function fetchStandings(year: number, champSeries: boolean = false) {
  try {
    const standingsRes = await callGraphQL<StandingsRes>(standingsQuery, {
      year: year,
      champSeries: champSeries,
    })
    return standingsRes.standings
  } catch (error) {
    return null
  }
}
