import { API_ENDPOINTS } from '../utils/endpoints'
import { makeAPIRequest, QueryParams } from '../utils/request'
import { FeedItem, GetFeedParams } from './feed.types'

export const getFeedBySlug = async (
  params: GetFeedParams
): Promise<FeedItem[] | null> => {
  try {
    const response = await makeAPIRequest(API_ENDPOINTS.GET_FEED_BY_TAG, {
      method: 'GET',
      apiVersion: '3',
      queryParams: params as QueryParams,
    })
    if (!response?.data) {
      console.error(response.error?.message)
      return null
    }
    return response.data as FeedItem[]
  } catch (err) {
    console.log(err)
    return null
  }
}
