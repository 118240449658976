const Constants = {}
Constants.pllApiBaseUrl = process.env.REACT_APP_PLL_API_BASE_URL || ''
Constants.pllApiVersion = process.env.REACT_APP_PLL_API_VERSION

Constants.standingsHeaders = [
  { W: 'Wins' },
  { L: 'Losses' },
  { SF: 'Scores For' },
  { SA: 'Scores Against' },
  { Diff: 'Score Differential' },
]
Constants.HASHED_TEAMS = [
  {
    teamCode: 'ARC',
    shortName: 'Archers',
    longTeamName: 'Archers LC',
    teamLogo: 'archersLogoBlock',
  },
  {
    teamCode: 'ATL',
    shortName: 'Atlas',
    longTeamName: 'Atlas LC',
    teamLogo: 'atlasLogoBlock',
  },
  {
    teamCode: 'CAN',
    shortName: 'Cannons',
    longTeamName: 'Cannons LC',
    teamLogo: 'cannonsLogoBlock',
  },
  {
    teamCode: 'CHA',
    shortName: 'Chaos',
    longTeamName: 'Chaos LC',
    teamLogo: 'chaosLogoBlock',
  },
  {
    teamCode: 'CHR',
    shortName: 'Chrome',
    longTeamName: 'Chrome LC',
    teamLogo: 'chromeLogoBlock',
  },
  {
    teamCode: 'OUT',
    shortName: 'Outlaws',
    longTeamName: 'Outlaws LC',
    teamLogo: 'outlawsLogoBlock',
  },
  {
    teamCode: 'RED',
    shortName: 'Redwoods',
    longTeamName: 'Redwoods LC',
    teamLogo: 'redwoodsLogoBlock',
  },
  {
    teamCode: 'WAT',
    shortName: 'Waterdogs',
    longTeamName: 'Waterdogs LC',
    teamLogo: 'waterdogsLogoBlock',
  },
  {
    teamCode: 'WHP',
    shortName: 'Whipsnakes',
    longTeamName: 'Whipsnakes LC',
    teamLogo: 'whipsnakesLogoBlock',
  },
]
Constants.months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]
Constants.days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export default Constants
