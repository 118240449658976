import React from 'react'
import { NavToggleStyle } from './styles'

type ToggleProps = {
  open: boolean
  setOpen: any
}

export const NavToggle: React.FC<ToggleProps> = ({ open, setOpen }) => {

  return (
    <NavToggleStyle open={open} onClick={() => setOpen(!open)}>
      <span></span>
      <span></span>
      <span></span>
    </NavToggleStyle>
  )
}
