import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import DefaultPage from '../../Pages/DefaultPage/DefaultPage'

const AdProvider = lazy(() =>
  import('../../Context/AdContext/AdContext').then((module) => ({
    default: module.AdProvider,
  }))
)

const AuthProvider = lazy(() =>
  import('../../Api').then((module) => ({
    default: module.AuthProvider,
  }))
)
const SeasonProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.SeasonProvider,
  }))
)
const VideoProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.VideoProvider,
  }))
)

export const AppContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<DefaultPage />}>
      <AdProvider>
        <AuthProvider allowAnonymous>
          <SeasonProvider>
            <VideoProvider>
              <Outlet />
            </VideoProvider>
          </SeasonProvider>
        </AuthProvider>
      </AdProvider>
    </Suspense>
  )
}
