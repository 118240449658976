import React, { useCallback } from 'react'
import { EventGQL } from '../../../../Api'
import { getGameTime } from '../../../../Utils'
import { StatusCon } from '../styles'

type StatusProps = {
  event: EventGQL
  period?: string
  clockTime?: string
}

export const BoxScoreStatus: React.FC<React.PropsWithChildren<StatusProps>> = ({
  event,
  period = '',
  clockTime = '',
}) => {
  let gameEnded = event.eventStatus >= 2
  let gameAlert = event.gameStatus !== ''
  let hasAlert = gameAlert ? false : true
  
  const displayPeriodAndTime = useCallback(() => {
    return (
      <>
        <p>{period}</p>
        <p>{clockTime}</p>
        <div className="liveAnimation">
          <div className="bar" />
        </div>
      </>
    )
  }, [clockTime])

  const renderStatus = () => {
    if (event?.eventStatus === 0 && !event?.gameStatus) {
      //Waiting to start, no delays
      return (
        <>
          <p>{getGameTime(event.startTime)}</p>
        </>
      )
    }
    if (event?.eventStatus >= 2 && event?.period <= 4) {
      return <p>FINAL</p>
    }
    if (event?.eventStatus >= 2 && event?.period > 4) {
      //Game ended in OT
      return <p>FINAL OT</p>
    }
    if (event?.eventStatus === 1) {
      //Game is currently live
      return <>{displayPeriodAndTime()}</>
    }
    if (event?.gameStatus && event?.eventStatus < 2) {
      //Game has been postponed or delayed
      return <p>{event.gameStatus}</p>
    }
    return ''
  }
  return (
    <StatusCon final={gameEnded} alertOn={hasAlert}>
      {renderStatus()}
    </StatusCon>
  )
}
