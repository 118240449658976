import { PlayerData } from '../../Api'

export const sortPlayerByFantasyStats = (
  stat: string,
  players: PlayerData[],
  ascDesc: -1 | 1
) => {
  switch (stat) {
    case 'FPTS':
      return players.sort((a, b) => {
        if (!a.fantasy || !b.fantasy) return 0
        let aStat = a.fantasy?.seasonTotalPts || 0
        let bStat = b.fantasy?.seasonTotalPts || 0
        return ascDesc === -1 ? bStat - aStat : aStat - bStat
      })
    case 'FPTS/G':
      return players.sort((a, b) => {
        if (!a.fantasy || !b.fantasy) return 0
        let aStat = a.fantasy?.avgPPG || 0
        let bStat = b.fantasy?.avgPPG || 0
        return ascDesc === -1 ? bStat - aStat : aStat - bStat
      })
    default:
      return players.sort((a, b) => {
        if (!a.fantasy || !b.fantasy) return 0
        let aStat = a.fantasy?.seasonTotalPts || 0
        let bStat = b.fantasy?.seasonTotalPts || 0
        return ascDesc === -1 ? bStat - aStat : aStat - bStat
      })
  }
}
