export const validateEmail = (email?: string) => {
    let errorMsg = ''
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
    if(!email){
        errorMsg = "Email is required."
         return {
             valid: false,
             errorMsg
         }
    }
      if (!pattern.test(email)) {
         errorMsg = "* Please enter valid email."
         return {
             valid: false,
             errorMsg
         }
      }
      return {
          valid: true,
          errorMsg: ''
      }
}