export const seasonEventsQuery = `
query($season: Int!, $includeCS: Boolean!) {
  seasonEvents(season: $season, includeCS: $includeCS) {
    id
    slugname
    externalId
    year
    startTime
    week
    venue
    description
    location
    broadcaster
    eventStatus
    period
    homeScore
    visitorScore
    urlTicket
    urlPreview
    urlStreaming
    addToCalendarId
    gameStatus
    seasonSegment
    league
    year
    awayTeam {
        officialId
        location
        locationCode
        urlLogo
        fullName
        teamWins
        teamLosses
        teamTies
        champSeries (year: $season) @include(if: $includeCS) {
          teamWins
          teamLosses
          teamTies
        }
      }
    homeTeam {
        officialId
        location
        locationCode
        urlLogo
        fullName
        teamWins
        teamLosses
        teamTies
        champSeries (year: $season) @include(if: $includeCS) {
          teamWins
          teamLosses
          teamTies
        }
    }
  }
}
`

export const eventInProgressBySlug = `
query($slug: ID) {
  event(slug:$slug) {
    eventStatus
    visitorScore
    homeScore
    period
    clockTenths
    clockSeconds
    clockMinutes
  }
}
`

export const eventInfoAndStatsById = `
query($id: ID) {
  event(slug: $id) {
    id
    slugname
    externalId
    year
    startTime
    week
    venue
    description
    location
    gameNumber
    broadcaster
    eventStatus
    period
    clockMinutes
    clockSeconds
    homeScore
    homePeriodScores
    visitorScore
    visitorPeriodScores
    urlTicket
    league
    urlPreview
    seasonSegment
    urlStreaming
    addToCalendarId
    gameStatus
    awayTeam {
      ...teamInfo
    }
    homeTeam {
      ...teamInfo
    }
    faceoffHeadToHead {
      player
      officialId
      opponentRecords{
        player
        officialId
        record{
          wins
          losses
        }
      }
    }
    liveWinProbablities {
      awayScore
      homeScore
      period
      clockMinutes
      clockSeconds
      secondsPassed
      awayTeamWinProbability
      homeTeamWinProbability
    }
  }
  }
  fragment teamInfo on Team {
    officialId
    location
    locationCode
    urlLogo
    fullName
    teamWins
    teamLosses
    teamTies
    stats(segment: event) {
      assists
      causedTurnovers
      clearAttempts
      clearPct
      clears
      faceoffPct
      faceoffs
      faceoffsLost
      faceoffsWon
      gamesPlayed
      goals
      goalsAgainst
      groundBalls
      manDownPct
      numPenalties
      onePointGoals
      pim
      powerPlayPct
      powerPlayGoals
      powerPlayShots
      powerPlayGoalsAgainst
      powerPlayGoalsAgainstPct
      rideAttempts
      saa
      savePct
      saves
      scores
      scoresAgainst
      shotClockExpirations
      shotPct
      shots
      shotsOnGoal
      shotsOnGoalPct
      timesManUp
      timesShortHanded
      turnovers
      twoPointGoalsAgainst
      twoPointShots
      twoPointShotPct
      twoPointShotsOnGoal
      twoPointShotsOnGoalPct
      twoPointGoals
      touches
      totalPasses
      timeInPossesion
      timeInPossesionPct
    }
    players: playersGameStats {
      officialId
      firstName
      lastName
      profileUrl
      slug
      allTeams {
        officialId
        location
        locationCode
        urlLogo
        league
        position
        positionName
        jerseyNum
        year
        fullName
      }
      stats(segment: event) {
        points
        faceoffPct
        shotPct
        twoPointShotPct
        twoPointShotsOnGoalPct
        savePct
        shotsOnGoalPct
        onePointGoals
        scoresAgainst
        saa
        gamesPlayed
        goals
        twoPointGoals
        assists
        groundBalls
        turnovers
        causedTurnovers
        faceoffsWon
        faceoffsLost
        faceoffs
        shots
        twoPointShots
        twoPointShotsOnGoal
        goalsAgainst
        twoPointGoalsAgainst
        numPenalties
        pim
        pimValue
        saves
        powerPlayGoals
        powerPlayShots
        shortHandedGoals
        shortHandedShots
        shortHandedGoalsAgainst
        powerPlayGoalsAgainst
        shotsOnGoal
        tof
        goalieWins
        goalieLosses
        goalieTies
        GAA
        twoPtGaa
        plusMinus
        foRecord
        assistOpportunities
        totalPasses
        touches
      }
    }
    }
`

export const liveEventInfoAndStats = `
query($id: ID) {
  event(slug: $id) {
    id
    slugname
    externalId
    year
    startTime
    week
    venue
    description
    location
    gameNumber
    broadcaster
    eventStatus
    period
    clockMinutes
    clockSeconds
    homeScore
    homePeriodScores
    visitorScore
    visitorPeriodScores
    urlTicket
    league
    urlPreview
    seasonSegment
    urlStreaming
    addToCalendarId
    gameStatus
    awayTeam {
      ...teamInfo
    }
    homeTeam {
      ...teamInfo
    }
    faceoffHeadToHead {
      player
      officialId
      opponentRecords{
        player
        officialId
        record{
          wins
          losses
        }
      }
    }
    liveWinProbablities {
      awayScore
      homeScore
      period
      clockMinutes
      clockSeconds
      secondsPassed
      awayTeamWinProbability
      homeTeamWinProbability
    }
  }
  }

  fragment teamInfo on Team {
    officialId
    location
    locationCode
    urlLogo
    fullName
    teamWins
    teamLosses
    teamTies
    stats(segment: event) {
      assists
      causedTurnovers
      clearAttempts
      clearPct
      clears
      faceoffPct
      faceoffs
      faceoffsLost
      faceoffsWon
      gamesPlayed
      goals
      goalsAgainst
      groundBalls
      manDownPct
      numPenalties
      onePointGoals
      pim
      powerPlayGoals
      powerPlayPct
      powerPlayShots
      powerPlayGoalsAgainst
      powerPlayGoalsAgainstPct
      rideAttempts
      saa
      savePct
      saves
      scores
      scoresAgainst
      shotClockExpirations
      shotPct
      shots
      shotsOnGoal
      shotsOnGoalPct
      timesManUp
      timesShortHanded
      turnovers
      twoPointGoalsAgainst
      twoPointShots
      twoPointShotPct
      twoPointShotsOnGoal
      twoPointShotsOnGoalPct
      twoPointGoals
      touches
      totalPasses
      timeInPossesion
      timeInPossesionPct
    }
    players: playersGameStats {
      officialId
      firstName
      lastName
      profileUrl
      slug
      allTeams {
        officialId
        location
        locationCode
        urlLogo
        league
        position
        positionName
        jerseyNum
        year
        fullName
      }
      stats(segment: event) {
        points
        faceoffPct
        shotPct
        twoPointShotPct
        twoPointShotsOnGoalPct
        savePct
        shotsOnGoalPct
        onePointGoals
        scoresAgainst
        saa
        gamesPlayed
        goals
        twoPointGoals
        assists
        groundBalls
        turnovers
        causedTurnovers
        faceoffsWon
        faceoffsLost
        faceoffs
        shots
        twoPointShots
        twoPointShotsOnGoal
        goalsAgainst
        twoPointGoalsAgainst
        numPenalties
        pim
        pimValue
        saves
        powerPlayGoals
        powerPlayShots
        shortHandedGoals
        shortHandedShots
        shortHandedGoalsAgainst
        powerPlayGoalsAgainst
        shotsOnGoal
        tof
        goalieWins
        goalieLosses
        goalieTies
        GAA
        twoPtGaa
        plusMinus
        foRecord
        assistOpportunities
        totalPasses
        touches
      }
    }
    }
`

export const broadcastLiveEventInfoAndStats = `
query($id: ID) {
  event(slug: $id) {
    id
    slugname
    externalId
    year
    startTime
    week
    venue
    description
    location
    gameNumber
    broadcaster
    eventStatus
    period
    clockMinutes
    clockSeconds
    clockTenths
    homeScore
    homePeriodScores
    visitorScore
    visitorPeriodScores
    urlTicket
    league
    urlPreview
    seasonSegment
    urlStreaming
    addToCalendarId
    gameStatus
    awayTeam {
      ...teamInfo
    }
    homeTeam {
      ...teamInfo
    }
    faceoffHeadToHead {
      player
      officialId
      opponentRecords{
        player
        officialId
        record{
          wins
          losses
        }
      }
    }
  }
  }

  fragment teamInfo on Team {
    officialId
    location
    locationCode
    urlLogo
    fullName
    teamWins
    teamLosses
    teamTies
    stats(segment: event) {
      assists
      causedTurnovers
      clearAttempts
      clearPct
      clears
      faceoffPct
      faceoffs
      faceoffsLost
      faceoffsWon
      gamesPlayed
      goals
      goalsAgainst
      groundBalls
      manDownPct
      numPenalties
      onePointGoals
      pim
      powerPlayGoals
      powerPlayGoalsAgainst
      powerPlayGoalsAgainstPct
      rideAttempts
      saa
      savePct
      saves
      scores
      scoresAgainst
      shotClockExpirations
      shotPct
      shots
      shotsOnGoal
      shotsOnGoalPct
      timesManUp
      timesShortHanded
      turnovers
      twoPointGoalsAgainst
      twoPointShots
      twoPointShotsOnGoal
      twoPointGoals
      touches
      totalPasses
    }
    players {
      officialId
      firstName
      lastName
      profileUrl
      slug
      allTeams {
        officialId
        location
        locationCode
        urlLogo
        league
        position
        positionName
        jerseyNum
        year
        fullName
      }
      stats(segment: event) {
        points
        faceoffPct
        shotPct
        twoPointShotPct
        twoPointShotsOnGoalPct
        savePct
        shotsOnGoalPct
        onePointGoals
        scoresAgainst
        saa
        gamesPlayed
        goals
        twoPointGoals
        assists
        groundBalls
        turnovers
        causedTurnovers
        faceoffsWon
        faceoffsLost
        faceoffs
        shots
        twoPointShots
        twoPointShotsOnGoal
        goalsAgainst
        twoPointGoalsAgainst
        numPenalties
        pim
        pimValue
        saves
        powerPlayGoals
        powerPlayShots
        shortHandedGoals
        shortHandedShots
        shortHandedGoalsAgainst
        powerPlayGoalsAgainst
        shotsOnGoal
        tof
        goalieWins
        goalieLosses
        goalieTies
        GAA
        twoPtGaa
        plusMinus
        foRecord
        assistOpportunities
        totalPasses
        touches
      }
    }
    }
`

export const playLogsByEvent = `
query($eventId: String!) {
  playLogs(eventId: $eventId){
    eventType
    period
    minutes
    seconds
    teamId
    descriptor
    markerId
    faceoffWinnerId
    faceoffWinnerId
    faceoffWinnerName
    faceoffLoserId,
    faceoffLoserName
    gbPlayerId
    gbPlayerName
    commitedTurnoverId
    commitedTurnoverName
    causedTurnoverId
    causedTurnoverName
    shooterId
    shooterName
    goalieId
    goalieName
    offenseGoalieId
    offenseGoalieName
    commitedPenaltyId
    commitedPenaltyName
    shotAssistId
    shotAssistName
    goalMarkerId
    penaltyLength
    penaltyDescription
    homeScore
    visitorScore
    secondsPassed
  }
}`
