import { EventGQL, fetchLiveEventByIdGQL } from "../../Api"

/**
 *
 * Fetch a game that is live to get clock time updates
 */
export const updateInProgressGame = async (ev: EventGQL) => {
  try {
    const updatedEvent = await fetchLiveEventByIdGQL(ev.slugname)
    if (!updatedEvent) return null
    return updatedEvent
  } catch (err) {
    console.log('Error getting in progress game', err)
    return null
  }
}

export const renderQuarterScore = (eventInfo: EventGQL, idx: number, teamStatus: string) => {
    if (eventInfo && eventInfo.eventStatus > 0) {
      if (idx === 0 && eventInfo.eventStatus === 1) {
        return teamStatus === 'away'
          ? eventInfo.visitorPeriodScores[idx] || 0
          : eventInfo.homePeriodScores[idx] || 0
      }
      if (teamStatus === 'away') {
        if (eventInfo.eventStatus > 1) {
          if (eventInfo.period > eventInfo.visitorPeriodScores.length) {
            return 0
          }
          return eventInfo.visitorPeriodScores[idx] || 0
        }
        return eventInfo?.visitorPeriodScores.length > idx
          ? eventInfo.visitorPeriodScores[idx]
          : '-'
      }
      if (teamStatus === 'home') {
        if (eventInfo.eventStatus > 1) {
          if (eventInfo.period > eventInfo.homePeriodScores.length) {
            return 0
          }
          return eventInfo.homePeriodScores[idx] || 0
        }
        return eventInfo?.homePeriodScores.length > idx
          ? eventInfo.homePeriodScores[idx]
          : '-'
      }
      return '-'
    }
    return '-'
  }