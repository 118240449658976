import React, { useContext, useEffect } from 'react'
import { styled } from '@mui/system'
import { AuthContext } from '../../Api/firebase'
import { Box } from '../Layout'

export const LogoutBtn = styled('button')(({ theme }) => ({
  background: '#fff',
    border: 'solid 1px #efefef',
    borderRadius: 5,
}))

export const UserIndicator: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isAnonymous, firstName, isLoggedIn, uid, login, logout} = useContext(AuthContext)!

  useEffect(() => {
    console.log('User id changed')

  }, [uid])
  return (
    <Box>
    Signed in as {' '}
    <p>
      {!isLoggedIn && 'not logged in'}
      {isLoggedIn && firstName}
    </p>{' '}
    {isLoggedIn && !isAnonymous && (
      <LogoutBtn onClick={logout}>logout</LogoutBtn>
    )}
    {(!isLoggedIn || isAnonymous) && (
      <button onClick={() => login()}>login</button>
    )}
  </Box>
  )
}
