import { PLL_LINKS } from '../Config/League/league.config'
/**
 * Convert fractional value 0-1 to percentage representation
 */
export function Percent(value: number): string {
  let pctStr = `${(value * 100).toFixed(1)}`
  let cleaned = parseFloat(pctStr)
  return `${cleaned}%`
}

/**
 * Convert fractional value 0-1 to percentage representation as number
 */
export function PercentNoDigit(value: number): string {
  return `${(value * 100).toFixed(0)}%`
}

/**
 * Convert fractional value 0-1 to percentage representation with two digits precision
 */
export function Percent2Digits(value: number): string {
  return `${(Math.round(value * 10000) / 100).toFixed(2)}%`
}

/**
 * Convert value with decimal to representation with one digit precision
 */
export function Decimal1Digit(value: number): string {
  return `${Math.round(value * 10) / 10}`
}

/**
 * Convert value with decimal to representation with two digits precision
 */
export function Decimal2Digit(value: number): string {
  return `${Math.round(value * 100) / 100}`
}

/**
 * Convert value with decimal to representation with two digits precision
 */
export function Decimal3Digit(value: number): string {
  return `${Math.round(value * 1000) / 1000}`
}

/**
 * Convert value with decimal to representation with one digit precision
 */
export function Decimal1DigitNum(value: number): number {
  return Math.round(value * 10) / 10
}

/**
 * Convert value to percent if valueName ends with 'pct' and is numeric type
 */
export function ConvertStatValue<T>(value: T, valueName: string): T | string {
  const checkedVal = stringToNumber(value)

  if (valueName.toLowerCase() === 'timeinpossesion') {
    return typeof value === 'number'
      ? formatDuration(value)
      : checkedVal !== null
      ? formatDuration(checkedVal)
      : '-'
  }
  if (valueName.toLowerCase().endsWith('pct')) {
    if (typeof value === 'number') {
      return Percent(value)
    }
    if (checkedVal !== null) return Percent(checkedVal)
    if (value === null || value === undefined) return '-'
  }
  if (valueName.toLowerCase().endsWith('aa') || valueName.endsWith('PG')) {
    if (typeof value === 'number') {
      return Decimal1Digit(value)
    }
    if (checkedVal !== null) return Decimal1Digit(checkedVal)
    if (value === null || value === undefined) return '-'
  }
  if (valueName === 'foRecord') {
    if (value === null || value === undefined) return '-'
    return value
  }

  if (valueName.toLowerCase().endsWith('rate')) {
    if (value === null || value === undefined || Number.isNaN(value)) return '-'
    if (typeof value === 'number') {
      return Percent2Digits(value)
    }
    if (checkedVal !== null) return Decimal1Digit(checkedVal)
  }

  if (valueName.toLowerCase().includes('ppg')) {
    if (typeof value === 'number') {
      return Decimal1Digit(value)
    }
    if (checkedVal !== null) return Decimal1Digit(checkedVal)
    if (value === null || value === undefined) return '-'
  }
  if (value === null || value === undefined) return '0'
  return value
}

/**
 * Convert value to percent if valueName ends with 'pct' and is numeric type
 */
export function ConvertDecimals(value: number, valueName: string): number {
  if (
    valueName.toLowerCase().endsWith('pct') ||
    valueName.toLowerCase().endsWith('aa')
  ) {
    if (typeof value === 'number') {
      // return Percent(value)
      return Decimal1DigitNum(value)
    }
  }
  return value
}

export const concatCollege = (college: string, collegeYear: string) => {
  const shortYear = collegeYear.slice(-2)
  const collegeAndYear = `${college} '${shortYear}`
  return collegeAndYear
}

export const calcPercent = (value: number, total: number) => {
  return ((value / total) * 100).toFixed(0)
}

type ShopParams = {
  medium: string
  slug?: string
  campaign: string
}

export const createShopUTM = ({ medium, campaign, slug }: ShopParams) => {
  return `${PLL_LINKS.shop}/${
    slug ? slug : ''
  }?utm_source=Web_Main&utm_medium=${medium}&utm_campaign=${campaign}`
}

/**
 *
 * Converts date from YYYY/MM/DD to Month Day, Year
 */
export const convertHourMinSec = (duration: number) => {
  // Hours, minutes and seconds
  let hrs = ~~(duration / 3600)
  let mins = ~~((duration % 3600) / 60)
  let secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

export const removeNumDups = (arr: number[]) => {
  //@ts-ignore
  let unique = [...new Set(arr)]
  return unique
}

export const stringToNumber = (input: any): number | null => {
  if (typeof input === 'number') return input //No need to convert
  const result = parseFloat(input)
  return isNaN(result) ? null : result
}

/**
 * Returns the number ordinal of the number passed in
 */
export const formatNumberOrdinal = (number?: number, justOrdinal?: boolean) => {
  if(!number) return ''
  let j = number % 10
  let k = number % 100

  if (j === 1 && k !== 11) {
    return justOrdinal ? 'st' : number + 'st'
  }

  if (j === 2 && k !== 12) {
    return justOrdinal ? 'nd' : number + 'nd'
  }

  if (j === 3 && k !== 13) {
    return justOrdinal ? 'rd' : number + 'rd'
  }

  return justOrdinal ? 'th' : number + 'th'
}

export const formatDuration = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes}m ${remainingSeconds}s`
}

/**
 *
 * @param string
 * @returns string with first letter capitalized
 */
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
