import { EventGQL, PlayLogs, Team } from '../../../../Api'
import { formatPlayerName } from '../../../../Utils'

/**
 * Returns opponent official id
 */
export const getOpponentId = (awayTeam: Team, homeTeam: Team, teamId: string) => {
  return awayTeam?.officialId === teamId
    ? homeTeam?.locationCode ?? homeTeam?.officialId
    : awayTeam?.locationCode ?? awayTeam?.officialId
}

/**
 * Returns play log description for a shot or goal
 */
export const createShotPlayLog = (log: PlayLogs, event: EventGQL) => {
  if (log?.descriptor && log.descriptor.includes('SHOT GOOD')) {
    //POWER PLAY 2PT GOALS
    if (log?.descriptor && log.descriptor.includes('MU 2 PT')) {
      if (
        event.seasonSegment === 'allstar' &&
        log?.descriptor &&
        log.descriptor.includes('BONUS')
      ) {
        // BONUS 2 PT POWERPLAY GOAL
        return `[2X BONUS] PP 2PT G by ${formatLogPlayerName(log.shooterName)}. ${
          log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
        }`
      }
      // 2PT POWERPLAY GOAL
      return `PP 2PT GOAL by ${formatLogPlayerName(log.shooterName)}. ${
        log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
      }`
    }

    //POWER PLAY 1PT GOALS
    if (log.descriptor.includes('MU SHOT GOOD')) {
      if (
        event.seasonSegment === 'allstar' &&
        log?.descriptor &&
        log.descriptor.includes('BONUS')
      ) {
        // BONUS POWERPLAY GOAL
        return `[2X BONUS] PP Goal by ${formatLogPlayerName(log.shooterName)}. ${
          log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
        }`
      }
      // POWERPLAY GOAL
      return `PP GOAL by ${formatLogPlayerName(log.shooterName)}. ${
        log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
      }`
    }

    // 2PT GOALS
    if (log.descriptor.includes('2 PT')) {
      if (
        event.seasonSegment === 'allstar' &&
        log?.descriptor &&
        log.descriptor.includes('BONUS')
      ) {
        // 2PT GOAL BONUS
        return `[2X BONUS] 2PT G by ${log.shooterName}. ${
          log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
        }`
      }
      // 2PT GOAL
      return `2PT G by ${log.shooterName}. ${
        log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
      }`
    }

    //1PT GOALS
    if (
      event.seasonSegment === 'allstar' &&
      log?.descriptor &&
      log.descriptor.includes('BONUS')
    ) {
      //1PT GOAL BONUS
      return `[2X BONUS] GOAL by ${log.shooterName}. ${
        log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
      }`
    }

    //1PT GOAL (default)
    return `GOAL by ${log.shooterName}. ${
      log.shotAssistName ? `Assist ${formatLogPlayerName(log.shotAssistName)}.` : ''
    }`
  } else {
    // Shot was missed
    return `Missed shot by ${log.shooterName}. ${
      log.offenseGoalieId
        ? `Save by ${getOpponentId(
            event.awayTeam,
            event.homeTeam,
            log.teamId!
          )} ${formatLogPlayerName(log.offenseGoalieName)}.`
        : ''
    }`
  }
}

/**
 * Returns play log description for a faceoff
 */
export const createFaceoffPlayLog = (log: PlayLogs, showWinType: boolean = true) => {
  return `Faceoff win ${formatLogPlayerName(log.faceoffWinnerName)} (vs. ${formatLogPlayerName(
    log?.faceoffLoserName
  )}).${
    showWinType ? ` GB ${log.teamId} ${formatLogPlayerName(log.gbPlayerName)}.` : ''
  }`
}

/**
 * Returns play log description for a groundball
 */
export const createGroundballPlayLog = (log: PlayLogs) => {
  return `Groundball picked up by ${formatLogPlayerName(log.gbPlayerName)}.`
}

/**
 * Returns play log description for a shot clock expiration
 */
export const createShotClockExpPlayLog = (log: PlayLogs) => {
  return `Shot Clock Violation.`
}

/**
 * Returns play log description for a clear
 */
export const createClearPlayLog = (log: PlayLogs) => {
  return `Successful clear.`
}

/**
 * Returns play log description for a turnover
 */
export const createTurnoverPlayLog = (log: PlayLogs, event: EventGQL) => {
  return `Turnover by ${log.teamId} ${formatLogPlayerName(log.commitedTurnoverName)}. ${
    log.causedTurnoverId
      ? `Caused turnover by ${getOpponentId(
          event.awayTeam,
          event.homeTeam,
          log.teamId!
        )} ${formatLogPlayerName(log.causedTurnoverName)}.`
      : ''
  }`
}

/**
 * Returns play log description for a turnover
 */
export const createPenaltyPlayLog = (log: PlayLogs, event: EventGQL) => {
  return `${log.teamId} ${log.commitedPenaltyName} ${convertPenTime(
    log.penaltyLength
  )} penalty for ${log.penaltyDescription}. ${getOpponentId(
    event.awayTeam,
    event.homeTeam,
    log.teamId!
  )} on the Power Play.`
}

/**
 * Returns play log description for a turnover
 */
export const createTimeoutPlayLog = (log: PlayLogs) => {
  return `Timeout called.`
}

/**
 * Returns play log description for a turnover
 */
export const createPowerPlayPlayLog = (log: PlayLogs) => {
  return `End of ${log.teamId} Power Play.`
}

/**
 * Returns play log description for a turnover
 */
export const convertPlayLogType = (log: PlayLogs) => {
  switch (log.eventType) {
    case 'shot':
      if (log.descriptor.includes('SHOT GOOD')) {
        return 'Goal'
      }
      return 'Shot'
    case 'faceoff':
      return 'Faceoff'
    case 'turnover':
      return 'Turnover'
    case 'groundball':
      return 'Groundball'
    case 'ballcleared':
      return 'Clear'
    case 'shotclockexpired':
      return 'Violation'
    case 'timeoutcalled':
      return 'Timeout'
    case 'powerplayend':
      return 'Power Play'
    case 'penalty':
      return 'Penalty'
    default:
      return ''
  }
}

/**
 * Returns play log penalty time in minutes
 */
export const convertPenTime = (time: string) => {
  if (time === '60') return '1 min'
  if (time === '90') return '1.5 min'
  return '30 sec'
}

const formatLogPlayerName = (name?: string) => {
  if (!name) return ''
  return formatPlayerName(name.split(' ')[0], name.split(' ')[1])
}
