import { colors, fontSizes } from '../../../Constants/consts'
import { styled } from '@mui/system'

export type StyleProps = {
  homeColor?: string
  awayColor?: string
  teamColor?: string
  uiTheme?: 'light' | 'dark'
}

export const BoxCon = styled('div')<StyleProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(0.5),
  borderRadius: 6,
  border: `0.5px solid ${colors.lightestGrey}`,
  width: 320,
  height: 165,
  background: colors.offWhite,
  [theme.breakpoints.between('md', 'lg')]: {
    width: 300,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: theme.spacing(1),
  },
}))

export const BoxScoreInfoCon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: theme.spacing(1, 1.5),
  borderBottom: `0.5px solid ${colors.lightestGrey}`,
  paddingBottom: theme.spacing(1),
  '& .weekCol': {
    display: 'flex',
    flexDirection: 'column',
    '& .week': {
      fontWeight: 500,
      fontSize: fontSizes.xSmall,
      color: colors.gray.gray60,
    },
    '& .dateBoxText': {
      fontWeight: 800,
      fontSize: '0.7rem',
      color: colors.text,
    },
  },
}))

export const GameCon = styled('div')<StyleProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  background: colors.offWhite,
  '& .teamsAndScoresCol': {
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
    '& .teamAndScoreRow': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
  },
}))

export const Game = styled('div')<StyleProps>(({ homeColor, awayColor, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  margin: theme.spacing(1),
  minHeight: '85px',
  border: `1px solid #ddd`,
  borderLeftWidth: 2,
  borderRightWidth: 2,
  borderColor: `#ddd ${homeColor} #ddd ${awayColor}`,
  borderRadius: 5,
  background: '#fff',
}))

export const ScoreBox = styled('div')<StyleProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(1),
  minHeight: 35,
  minWidth: 70,
  [theme.breakpoints.between('sm', 'md')]: {
    minHeight: 32,
    minWidth: 64,
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 30,
    minWidth: 60,
  },
}))

type BoxScoreActionsParams = {
  isLive?: boolean
}

export const BoxscoreActionPrimary = styled('a')<BoxScoreActionsParams>(({ isLive, theme }) => ({
  margin: theme.spacing(0, 1, 0, 0),
  borderRadius: 12,
  background: isLive ? colors.lightBlue : colors.yellow,
  padding: '3px 8px',
  color: isLive ? colors.white : colors.text,
  fontSize: fontSizes.xSmall,
  fontWeight: 800,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:focus': {
    outline: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    fontWeight: 500,
  },
}))

export const BoxscoreActionSecondary = styled('a')<StyleProps>(({ theme }) => ({
  margin: theme.spacing(0, 1, 0.5, 1),
  borderRadius: 12,
  padding: '2px 8px',
  color: colors.blue,
  fontSize: fontSizes.xSmall,
  fontWeight: 700,
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
  },
  '&:hover': {
    color: colors.text,
  },
  textDecoration: 'none',
  [theme.breakpoints.down('sm')]: {
    fontWeight: 600,
  },
}))

export const TeamInfoCon = styled('a')<StyleProps>(({ theme, uiTheme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: theme.spacing(0, 1),
  textDecoration: 'none',
  height: 40,
  '& .logoCon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    '& .genImg': {
      height: 25,
    },
  },
  '& .nameAndRecordCon': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 5,
    '& .teamName': {
      color: uiTheme === 'dark' ? colors.gray.gray40 : colors.text,
      margin: 0,
      fontSize: fontSizes.small,
      fontWeight: 800,
      [theme.breakpoints.down('sm')]: {
        fontSize: fontSizes.xSmall,
      },
    },
    '& .record': {
      color: uiTheme === 'dark' ? colors.gray.gray60 : colors.gray.gray60,
      margin: 0,
      fontSize: fontSizes.xSmall,
      fontWeight: 400,
      whitespace: 'nowrap'
    },
  },
}))

type ScoreStyleProps = {
  leader?: boolean
}

export const ScoreCon = styled('div')<ScoreStyleProps>(({ leader, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  margin: theme.spacing(1),
  '& .score': {
    color: leader ? colors.text : colors.gray.gray60,
    fontSize: fontSizes.semiLarge,
    fontWeight: 800,
    width: 35,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizes.medium,
    },
  },
  '& .winIcCon': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 2,
    width: 10,
    '& winIc': {
      display: leader ? 'block' : 'none',
    },
  },
}))

type StatusConProps = {
  final: boolean
  alertOn: boolean
}

export const StatusCon = styled('div')<StatusConProps>(({ final, alertOn, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginRight: theme.spacing(1),
  flexGrow: 1,
  '& p': {
    margin: theme.spacing(0, 1),
    fontSize: fontSizes.small,
    fontWeight: 700,
    color: alertOn ? colors.green : final ? colors.gray.gray60 : colors.text,
  },
  '& .liveAnimation': {
    width: '30% !important',
    height: 3,
    borderRadius: 5,
    marginTop: 2,
    position: 'relative',
    overflow: 'hidden',
    '& .bar': {
      width: '100% ',
      height: 3,
      borderRadius: 5,
      background: colors.white,
      position: 'absolute',
      animationName: 'move',
      animationDuration: ' 8s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
    },
    '@keyframes move': {
      '0%': {
        transform: 'translateX(-100%)',
        background: colors.green,
      },
      '15%': {
        transform: 'translateX(100%)',
      },
      '30%': {
        transform: 'translateX(-100%)',
      },
      '65%': {
        background: colors.white,
      },
      '100%': {
        transform: 'translateX(-100%)',
      },
    },
  },
}))

export const BroadcastersCon = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  '& img': {
    width: 38,
    margin: '0 2px',
  },
}))

export const BoxScoreBottomRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textDecoration: 'none',
  borderTop: `0.5px solid ${colors.lightestGrey}`,
  paddingTop: theme.spacing(1),
  margin: theme.spacing(1, 2),
}))

type StatusProps = {
  statusOn: boolean
}

export const StatusBox = styled('div')<StatusProps>(({ statusOn, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& p': {
    fontSize: '0.6rem',
    fontWeight: 700,
    color: colors.green,
    marginTop: 3,
    marginBottom: 0,
  },
  '& .status': {
    textAlign: 'center',
    fontSize: '0.8rem',
    fontWeight: 700,
    marginTop: statusOn ? 0 : theme.spacing(1),
  },
}))
