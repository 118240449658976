import React, { createContext, useState, useEffect, useContext } from 'react'
import { SeasonContext } from '../SeasonContext'
import { ConferenceStanding, fetchStandings, TeamStanding } from '../../Api'
import { Season } from '../SeasonContext/SeasonContext'
import { filterChampSeriesStandings } from '../../Utils'

type StandingsContextType = {
  standings: TeamStanding[]
  conferenceStandings: ConferenceStanding
  loading: boolean
  getStandings: (season: Season) => Promise<void>
}

export const StandingsContext = createContext<StandingsContextType | null>(null)

export const StandingsProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { seasonSelect } = useContext(SeasonContext)!
  const [standings, setStandings] = useState<TeamStanding[]>([])
  const [conferenceStandings, setConferenceStandings] = useState<ConferenceStanding>({eastern: [], western: []})
  const [loading, setLoading] = useState<boolean>(true)
  const [yearObj, setYearObj] = useState<Season>(seasonSelect)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (seasonSelect.year !== yearObj.year || seasonSelect.seasonType !== yearObj.seasonType) {
      // Refetch & update standings
      fetchData()
      setYearObj(seasonSelect)
    }
  }, [seasonSelect])

  const fetchData = async (): Promise<any> => {
    await getStandings(seasonSelect)
  }


  const getStandings = async (season: Season) => {
    let champSeriesSelected = seasonSelect.seasonType === 'champseries'
    try {
      let teams = await fetchStandings(season.year, champSeriesSelected)
      if (!teams) return []
      if(seasonSelect.seasonType === 'champseries') {
        teams = filterChampSeriesStandings(teams, seasonSelect.year)
      }
      if (season.year >= 2024 && season.seasonType === 'regular'){
        const eastern = teams.filter(team => team.conference === 'eastern')
        const western = teams.filter(team => team.conference === 'western')
        setConferenceStandings({eastern: eastern, western: western})
      }
      setStandings(teams)
      setLoading(false)
      return
    } catch (err : any) {
      setLoading(false)
      return err
    }
  }

  return (
    <StandingsContext.Provider
      value={{
        standings,
        conferenceStandings,
        loading,
        getStandings
      }}
    >
      {children}
    </StandingsContext.Provider>
  )
}
