import React, { createContext, useState, useEffect } from 'react'
import { STATS_YEAR, CURRENT_YEAR, champSeriesStart, champSeriesEnd } from '../../Config/League/league.config'
import { isBetweenDates } from '../../Utils'

export type SeasonType = 'regular' | 'post' | 'allstar' | 'champseries'
export type Season = { seasonType: SeasonType; year: number }
type SeasonContextType = {
  seasonSelect: Season
  onSeasonDropdownChange: (seasonOpts: Season) => void
}

export const SeasonContext = createContext<SeasonContextType | null>(null)

export const SeasonProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [seasonSelect, setSeasonSelect] = useState<Season>({
    seasonType: isBetweenDates(champSeriesStart, champSeriesEnd)
      ? 'champseries'
      : 'regular',
    year: isBetweenDates(champSeriesStart, champSeriesEnd) ? CURRENT_YEAR : STATS_YEAR,
  })

  useEffect(() => {}, [])

  const onSeasonDropdownChange = async (seasonOpts: Season) => {
    setSeasonSelect(seasonOpts)
  }

  return (
    <SeasonContext.Provider
      value={{
        seasonSelect: seasonSelect,
        onSeasonDropdownChange: onSeasonDropdownChange,
      }}
    >
      {children}
    </SeasonContext.Provider>
  )
}
