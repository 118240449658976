import React from 'react'
import { SubMenu } from './SubMenu'
import { PLL_LINKS, TEAM_MENU, MORE_MENU } from '../../../Config/League/league.config'
import { MobileNavCon, MobileNavList } from '../SiteNavbar/styles'
import { createShopUTM } from '../../../Routing'

type MenuProps = {
  open: boolean
}

export const NavItems: React.FC<MenuProps> = ({ open }) => {

  return (
    <MobileNavCon open={open}>
      <MobileNavList open={open}>
        <SubMenu menuText="Teams ▾" options={TEAM_MENU}></SubMenu>
        <li>
          <a href={PLL_LINKS.schedule}>Schedule</a>
        </li>
        <li>
          <a href={PLL_LINKS.standings}>Standings</a>
        </li>
        <li>
          <a href={PLL_LINKS.fantasy}>Fantasy</a>
        </li>
        <li>
          <a href={PLL_LINKS.academy}>PLL Play</a>
        </li>
        <li>
          <a href={PLL_LINKS.whereToWatch}>Where To Watch</a>
        </li>
        <li>
          <a href={createShopUTM({
            slug: '',
            medium: 'PLL_STATS',
            campaign: 'STATS_NAVBAR'
          })}>Shop</a>
        </li>
        <SubMenu menuText="More ▾" options={MORE_MENU}></SubMenu>
      </MobileNavList>
    </MobileNavCon>
  )
}
