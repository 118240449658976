import React, { useEffect } from 'react'
import { NavComponent } from '../../Components/Navbar/NavComponent'
import { Box, PageContainer } from '../../Components/Layout'
import { useQuery } from '../../Routing'
import TableLoading from '../../Containers/Loading/TableLoading'

const DefaultPage: React.FC = () => {
  useEffect(() => {}, [])
  let query = useQuery()
  const navQuery = query.get('showNavBar')
  const shouldShowNav = navQuery === 'false' ? false : true

  return (
    <>
        <PageContainer>
          <NavComponent
            showSearch={false}
            showPLLNav={shouldShowNav}
            showStatsNav={shouldShowNav}
          />
          <Box rounded={6} >
           <TableLoading />
          </Box>
        </PageContainer>
    </>
  )
}

export default DefaultPage
