import { PLL_LINKS } from '../Config/League/league.config'

export const ROUTING_URLS = {
  TEAMS_URL: '/teams',
  PLAYERS_URL: '/players',
  PLAYER_STAT_LEADERS_URL: '/players',
  PLAYER_TABLE_URL: '/player-table',
  PLAYER_STATS_URL: '/player-table',
  TEAM_TABLE_URL: '/pll-team-table',
  ALL_TEAMS_TABLE_URL: '/team-table',
  ALL_TEAM_STATS_URL: '/team-table',
  GAMES_URL: '/games',
  BROADCAST_GAME_VIEW_URL: '/broadcast-game-view',
  BROADCAST_PLAY_LOGS_URL: '/broadcast-game-view/play-by-play',
  CAREER_LEADERS_URL: '/career-leaders',
  ADVANCED_STATS_URL: '/advanced-stats',
  LOGIN_URL: '/login',
  FANTASY_URL: '/fantasy'
}

export type SeasonType = 'regular' | 'post' | 'allstar' | 'champseries'

/**
 *
 * Create UTM Link
 */

type ShopParams = {
  medium: string
  slug?: string
  campaign: string
}
export const createShopUTM = ({ medium, campaign, slug }: ShopParams) => {
  return `${PLL_LINKS.shop}/${
    slug && slug
  }?utm_source=Web_Main&utm_medium=${medium}&utm_campaign=${campaign}`
}

export const createPlayerShopUTM = ({ medium, campaign, slug }: ShopParams) => {
  return `${slug}?utm_source=Web_Main&utm_medium=${medium}&utm_campaign=${campaign}`
}

export const createRoyaleAppLink = (week?: string) => {
  if (!week) {
    return `https://pllmain.page.link/?pageID=PlayerRoyale&link=https://premierlacrosseleague.com/fantasy&ibi=com.pll.PLL&apn=com.pll.pllandroid&ifl=https://premierlacrosseleague.com/fantasy`
  } else {
    return `https://pllmain.page.link/?pageID=PlayerRoyale&link=https://premierlacrosseleague.com/fantasy&week=${week}&ibi=com.pll.PLL&apn=com.pll.pllandroid&ifl=https://premierlacrosseleague.com/fantasy`
  }
}

type TicketParams = {
  ticketId: string
  medium: string
  campaign: string
  page?: string
  week?: string
  day?: string
}

export const createTicketUTM = ({
  ticketId,
  medium,
  campaign,
  page,
  week,
  day,
}: TicketParams) => {
  return `${ticketId}&utm_source=PLL_Stats&utm_medium=${medium}&utm_campaign=${campaign}&wt.mc_id=${getTMId(
    page,
    week,
    day
  )}`
}

const getTMId = (page: string | undefined, week: string | undefined, day?: string) => {
  if (!page || !week) return ''
  switch (page.toLowerCase()) {
    case 'schedule':
      return `PLL_LEAGUE_SCHEDULE_PAGE_WEEK${week ? week : ''}${day ? `_DAY${day}` : ''}`
    case 'tickets':
      return `PLL_LEAGUE_TICKETS_PAGE_WEEK${week ? week : ''}${day ? `_DAY${day}` : ''}`
    case 'ticker':
      return `PLL_LEAGUE_TICKER_WEEK${week ? week : ''}${day ? `_DAY${day}` : ''}`
    default:
      return ''
  }
}
