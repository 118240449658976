import {
  PlayerData,
  PlayersStatsData,
  PlayerWithStatsData,
  TeamData,
  Team,
  TeamStats,
} from '../../../Api'
type StatsFilterList = PlayersStatsData | PlayerWithStatsData | TeamStats
type GQLFilterList = PlayerData

/**
 * Filter for players that played in PLL only
 */
export const filterPLLInfo = (
  list: (PlayerData | Team | PlayerWithStatsData | TeamData)[]
): (PlayerData | Team | PlayerWithStatsData | TeamData)[] => {
  return list.filter((item) => item?.league && item?.league === 'PLL')
}

/**
 * Filter for players that played in PLL only
 */
export const filterPLLGQLInfo = (list: GQLFilterList[]) => {
  return list.filter((item) => item.team?.league === 'PLL')
}

/**
 * Filter for players that played in PLL only
 */
export const filterPLLTeams = (list: Team[]) => {
  return list.filter((item) => item.league === 'PLL')
}

/**
 * Filter stats for players that played in PLL only
 */
export const filterPLLStats = (list: StatsFilterList[]) => {
  return list.filter((item) => item.statLeague === 'PLL')
}
