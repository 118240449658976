import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { Autocomplete, PLLSearchResult } from './index'
import { SEARCH_CONFIG } from '../../Config/Search/search.config'
import { searchClient } from '../../Api/search/Algolia'
import { PLLContentSearchResult } from './PLLSearchResult'

export function AutocompleteContainer() {
  const debounced = debouncePromise((items: any) => Promise.resolve(items), 300)

  return (
    <Autocomplete
      openOnFocus={true}
      getSources={() =>
        debounced([
          {
            sourceId: 'pll',
            getItems({ query }: { query: string }) {
              if (query.length < 1) return []
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: SEARCH_CONFIG.players,
                    query,
                    params: {
                      hitsPerPage: 5,
                      clickAnalytics: true,
                    },
                  },
                  {
                    indexName: SEARCH_CONFIG.teams,
                    query,
                    params: {
                      hitsPerPage: 2,
                      clickAnalytics: true,
                    },
                  },
                  {
                    indexName: SEARCH_CONFIG.pll,
                    query,
                    params: {
                      hitsPerPage: 2,
                      clickAnalytics: true,
                    },
                  },
                ],
              } as any)
            },
            templates: {
              header() {
                return (
                  <div>
                    <span className="aa-SourceHeaderTitle">Search Results</span>
                    <div className="aa-SourceHeaderLine" />
                  </div>
                )
              },
              item({ item, components }: { item: any; components: any }) {
                if (item?.type && (item.type === 'article' || item.type === 'video')) {
                  return <PLLContentSearchResult hit={item} components={components} />
                } else {
                  return <PLLSearchResult hit={item} components={components} />
                }
              },
              noResults() {
                return 'No results'
              },
            },
          },
        ])
      }
    />
  )
}

// For delaying search until user stops typing
function debouncePromise(fn: any, time: number) {
  let timerId: any = undefined

  return function debounced(...args: any) {
    if (timerId) {
      clearTimeout(timerId)
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time)
    })
  }
}
