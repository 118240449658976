import { useParams, useLocation } from 'react-router-dom'
import {
  DYNAMIC_LINK_CONFIG,
  MAIN_PLL_URL
} from '../Config/League/league.config'

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export type TeamProfileUrlParams = {
  slug: string
  year: number
}

type DynamicLinkParam = {
  title: string
  value?: string | number
}

/**
 * Get current team name and other params from URL
 */
export const useTeamName = (): string => {
  const rparams: Record<string, string | undefined> = useParams()
  if (rparams.teamName) {
    return rparams.teamName
  }
  return ''
}

export const createDynamicLink = (
  params: DynamicLinkParam[],
  pageId: string,
  webUrl?: string
) => {
  const webLink = webUrl ? webUrl : MAIN_PLL_URL
  const dLink = `${DYNAMIC_LINK_CONFIG.baseUrl}?${
    pageId ? `pageID=${pageId}&` : ''
  }link=${webLink}&ibi=${DYNAMIC_LINK_CONFIG.ibi}&isi=${DYNAMIC_LINK_CONFIG.isi}&apn=${
    DYNAMIC_LINK_CONFIG.apn
  }${createLinkDataString(params)}`

  return dLink
}

const createLinkDataString = (params: DynamicLinkParam[]) => {
  if (params.length < 1) {
    return ''
  }
  let str = ''
  params.map((item) => {
    if (item.value) {
      let strToAdd = `&${item.title}=${item.value}`
      str = str + strToAdd
      return
    }
    return
  })
  return str
}
