import React from 'react'
import { EventGQL, Team } from '../../../../../Api'
import { Season, SeasonType } from '../../../../../Context/SeasonContext/SeasonContext'
import { createTeamDynamicLink, getTeamRecord } from '../../../../../Utils'
import { TeamImage } from '../../../../TeamComponents/TeamImage/TeamImage'
import { TeamInfoCon } from '../../styles'

type ActionProps = {
  event: EventGQL
  team: Team
  uiTheme?: 'light' | 'dark'
}

export const BoxScoreTeam: React.FC<React.PropsWithChildren<ActionProps>> = ({
  event,
  team,
  uiTheme = 'light',
}) => {
  let segment: Season = {
    year: event.year,
    seasonType: event.seasonSegment as SeasonType,
  }

  const createTeamLink = () => {
    if (event.seasonSegment === 'allstar') {
      return `/all-star`
    }
    return createTeamDynamicLink(team)
  }

  return (
    <TeamInfoCon href={createTeamLink()} uiTheme={uiTheme}>
      <div className="logoCon">
        <TeamImage
          imgSize="xs"
          teamId={team.officialId}
          teamSrc={team.urlLogo}
          year={event.year}
        />
      </div>
      <div className="nameAndRecordCon">
        <p className="teamName">
          {team.locationCode ? team.locationCode : team.officialId}
        </p>
        <p className="record">
          {event?.eventStatus < 2 &&
            event.seasonSegment !== 'allstar' &&
            team &&
            getTeamRecord(team, segment)}
        </p>
      </div>
    </TeamInfoCon>
  )
}
