import React from 'react'
import { getBroadcastImg, logEvent } from '../../../../../Utils'
import { EventGQL } from '../../../../../Api'
import { BroadcastersCon } from '../../styles'
import { Img } from '../../../../Images/Img'

type StatusProps = {
  event: EventGQL
}

export const Broadcasters: React.FC<React.PropsWithChildren<StatusProps>> = ({
  event,
}) => {
  return (
    <>
      {event?.broadcaster && (
        <BroadcastersCon
          href={event.urlStreaming}
          onClick={() =>
            logEvent('boxscore_click', {
              button: 'broadcaster',
              button_text: event.broadcaster.toString(),
              section: 'game_ticker',
            })
          }
        >
          {getBroadcastImg(event.broadcaster).map((img, idx) => (
            <Img key={idx} src={img} alt={event.broadcaster[idx]} />
          ))}
        </BroadcastersCon>
      )}
    </>
  )
}
