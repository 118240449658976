import React from 'react'
import { getGeneralTeamIc } from '../../../../../Utils'
import { TeamInfoCon } from '../../styles'
import { Img } from '../../../../Images/Img'

export const GeneralTeamRow: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <TeamInfoCon>
      <div className="logoCon">
        <Img className='genImg' src={getGeneralTeamIc()} alt="Team TBA" />
      </div>
      <div className="nameAndRecordCon">
        <p className="teamName">TBA</p>
      </div>
    </TeamInfoCon>
  )
}
