import { MAIN_PLL_URL } from "../Config/League/league.config"

export const teamStatsTypes = [
  'scoresPG',
  'saa',
  'shotsPG',
  'shotPct',
  'twoPointGoals',
  'assists',
  'faceoffPct',
  'savePct',
  'causedTurnovers',
  'powerPlayPct',
  'manDownPct',
  'totalPasses',
]
export const champSeriesTeamStatsTypes = [
  'scoresPG',
  'saa',
  'shotsPG',
  'shotPct',
  'twoPointGoals',
  'savePct',
]

export const teamBaseBannerUrls = {
  2023: `https://${MAIN_PLL_URL}/wp-content/uploads/2021/01`,
  2024: `https://${MAIN_PLL_URL}/wp-content/uploads/2024/05`,
  2025: `https://${MAIN_PLL_URL}/wp-content/uploads/2024/05`
}

export const teamDropdown = [
  { label: 'All Teams', value: 'All Teams' },
  { label: 'Archers', value: 'Archers' },
  { label: 'Atlas', value: 'Atlas' },
  { label: 'Cannons', value: 'Cannons' },
  { label: 'Chaos', value: 'Chaos' },
  { label: 'Outlaws', value: 'Outlaws' },
  { label: 'Redwoods', value: 'Redwoods' },
  { label: 'Waterdogs', value: 'Waterdogs' },
  { label: 'Whipsnakes', value: 'Whipsnakes' },
]

export const teamLocationCodes = {
  ARC: 'UTA',
  ATL: 'NYA',
  CAN: 'BOS',
  CHA: 'CAR',
  OUT: 'DEN',
  RED: 'CAL',
  WAT: 'PHI',
  WHP: 'MDW'
}

export const teamsBasicArray = [
  {
    officialId: 'ARC',
    fullName: 'Archers'
  },
  {
    officialId: 'ATL',
    fullName: 'Atlas',
  },
  {
    officialId: 'CAN',
    fullName: 'Cannons',
  },
  {
    officialId: 'CHA',
    fullName: 'Chaos',
  },
  {
    officialId: 'OUT',
    fullName: 'Outlaws',
  },
  {
    officialId: 'CHR',
    fullName: 'Chrome',
  },
  {
    officialId: 'RED',
    fullName: 'Redwoods',
  },
  {
    officialId: 'WAT',
    fullName: 'Waterdogs',
  },
  {
    officialId: 'WHP',
    fullName: 'Whipsnakes',
  },
  {
    officialId: 'ZPP',
    fullName: 'Player Pool',
  },
  {
    officialId: 'ASH',
    fullName: 'Veterans',
  },
  {
    officialId: 'ASA',
    fullName: 'Rising Stars',
  },
  {
    officialId: 'VET',
    fullName: 'Veterans',
  },
  {
    officialId: 'RIS',
    fullName: 'Rising Stars',
  },
]

export const teamColorStyles = {
  // ARCHERS //
  arcPrimary: {
    // color: '#222D3F',
    color: '#F2694C',
    fontWeight: '600',
  },
  arcSecondary: {
    color: '#222D3F',
    fontWeight: '600',
  },
  arcBackgroundPrimary: {
    background: '#F2694C',
  },
  arcBackgroundSecondary: {
    background: '#F2694C',
  },
  // ATLAS //
  atlPrimary: {
    color: '#0fa2c0',
    fontWeight: '600',
  },
  atlSecondary: {
    color: '#0fa2c0',
    fontWeight: '600',
  },
  atlBackgroundPrimary: {
    background: '#f1EcD4',
  },
  atlBackgroundSecondary: {
    background: '#f1EcD4',
  },
  // CANNONS //
  canPrimary: {
    color: '#002244',
    fontWeight: '600',
  },
  canSecondary: {
    color: '#002244',
    fontWeight: '600',
  },
  canBackgroundPrimary: {
    background: '#B0B7BC',
  },
  canBackgroundSecondary: {
    background: '#B0B7BC',
  },
  // CHAOS //
  chaPrimary: {
    color: '#ef484B',
    fontWeight: '600',
  },
  chaSecondary: {
    color: '#ef484B',
    fontWeight: '600',
  },
  chaBackgroundPrimary: {
    background: '#000000',
  },
  chaBackgroundSecondary: {
    background: '#000000',
  },
  // CHROME //
  chrPrimary: {
    color: '#95D6DB',
    fontWeight: '600',
  },
  chrSecondary: {
    color: '#EB5E99',
    fontWeight: '600',
  },
  chrTertiary: {
    color: '#EB5E99',
    fontWeight: '600',
  },
  chrBackgroundPrimary: {
    background: '#95D6DB',
  },
  chrBackgroundSecondary: {
    background: '#95D6DB',
  },
  // REDWOODS //
  redPrimary: {
    color: '#006F55',
    fontWeight: '600',
  },
  redSecondary: {
    color: '#EDEA5B',
    fontWeight: '600',
  },
  redBackgroundPrimary: {
    background: '#006F55',
  },
  redBackgroundSecondary: {
    background: '#EDEA5B',
  },
  // WATERDOGS //
  watPrimary: {
    color: '#907FE2',
    fontWeight: '600',
  },
  watSecondary: {
    color: '#D6D2FF',
    fontWeight: '600',
  },
  watBackgroundPrimary: {
    background: '#907FE2',
  },
  watBackgroundSecondary: {
    background: '#D6D2FF',
  },
  // WHIPSNAKES //
  whpPrimary: {
    color: '#81C1B0',
    fontWeight: '600',
  },
  whpSecondary: {
    color: '#E71C3F',
    fontWeight: '600',
  },
  whpBackgroundPrimary: {
    background: '#81C1B0',
  },
  whpBackgroundSecondary: {
    background: '#E71C3F',
  },
  // All Star DEFENDERS //
  ashPrimary: {
    color: '#ef484B',
    fontWeight: '600',
  },
  ashSecondary: {
    color: '#ef484B',
    fontWeight: '600',
  },
  ashBackgroundPrimary: {
    background: '#ef484B',
  },
  ashBackgroundSecondary: {
    background: '#ef484B',
  },
  // All Star ADVERSARIES //
  asaPrimary: {
    color: '#000000',
    fontWeight: '600',
  },
  asaSecondary: {
    color: '#000000',
    fontWeight: '600',
  },
  asaBackgroundPrimary: {
    background: '#000000',
  },
  asaBackgroundSecondary: {
    background: '#000000',
  },
}

export const standingsHeaders = [
  { code: 'W', label: 'Wins' },
  { code: 'L', label: 'Losses' },
  { code: 'T', label: 'Ties' },
  { code: 'SF', label: 'Scores For' },
  { code: 'SA', label: 'Scores Against' },
  { code: 'Diff', label: 'Score Differential' },
]

export const teamFullTableHeaders = [
  { W: 'Wins' },
  { L: 'Losses' },
  { S: 'Scores: Goals + Two Point Goals(2)' },
  { '1G': 'One Point Goals' },
  { '2G': 'Two Point Goals' },
  { A: 'Assists' },
  { Sh: 'Shots' },
  { 'Sh%': 'Shooting Percentage' },
  { '2Sh%': 'Two Point Shooting Percentage' },
  { SOG: 'Shots On Goal' },
  { TO: 'Turnovers' },
  { CT: 'Caused Turnovers' },
  { GB: 'Groundballs' },
  { FO: 'Faceoffs Won - Total Faceoffs Taken' },
  { 'FO%': 'Percentage of Faceoffs Won To Total Faceoffs' },
  { Sv: 'Saves' },
  { 'Sv%': 'Save Percentage' },
  { SA: 'Scores Against' },
  { SAA: 'Scores Against Average' },
  { PEN: 'Number of Penalties' },
  { PIM: 'Penalties In Minutes' },
  { 'PP%': 'Power Play Percentage' },
  { PP: 'Power Plays' },
  { PPSh: 'Power Play Shots' },
  { 'PK%': 'Penalty Kill Percentage' },
  { PK: 'Penalty Kills' },
]

export const teamAllStatsTableHeaders = [
  { S: 'Scores: Goals + Two Point Goals(2)' },
  { '1G': 'One Point Goals' },
  { '2G': 'Two Point Goals' },
  { A: 'Assists' },
  { Sh: 'Shots' },
  { 'Sh%': 'Shooting Percentage' },
  { SOG: 'Shots On Goal' },
  { TO: 'Turnovers' },
  { CT: 'Caused Turnovers' },
  { GB: 'Groundballs' },
  { FO: 'Faceoffs Won - Total Faceoffs Taken' },
  { 'FO%': 'Percentage of Faceoffs Won To Total Faceoffs' },
  { Sv: 'Saves' },
  { 'Sv%': 'Save Percentage' },
  { SA: 'Scores Against' },
  { PEN: 'Number of Penalties' },
  { PIM: 'Penalties In Minutes' },
  { 'PP%': 'Power Play Percentage' },
  { PP: 'Power Plays' },
  { 'PK%': 'Penalty Kill Percentage' },
  { PK: 'Penalty Kills' },
  { TCH: 'Touches' },
  { PAS: 'Total Passes' },
]

export const teamHashTable = {
  W: 'teamWins',
  L: 'teamLosses',
  S: 'scores',
  '1G': 'onePointGoals',
  '2G': 'twoPointGoals',
  A: 'assists',
  Sh: 'shots',
  'Sh%': 'shotPct',
  '2Sh%': 'twoPointShotPct',
  SOG: 'shotsOnGoal',
  TO: 'turnovers',
  CT: 'causedTurnovers',
  GB: 'groundBalls',
  FO: 'faceoffsWon',
  'FO%': 'faceoffPct',
  Sv: 'saves',
  'Sv%': 'savePct',
  SA: 'scoresAgainst',
  SAA: 'saa',
  PEN: 'numPenalties',
  PIM: 'pim',
  'PP%': 'powerPlayPct',
  PP: 'powerPlayGoals',
  PPSh: 'powerPlayShots',
  'PK%': 'manDownPct',
  PK: 'manDownPct',
}

export const teamCSVHeaders: {displayName: string, id: string}[] = [
  { displayName: 'Team ID', id: 'officialId' },
  { displayName: 'Team', id: 'team' },
  { displayName: 'Scores', id: 'scores' },
  { displayName: 'Goals', id: 'goals' },
  { displayName: 'One Point Goals', id: 'onePointGoals' },
  { displayName: 'Two Point Goals', id: 'twoPointGoals' },
  { displayName: 'Assists', id: 'assists' },
  { displayName: 'Shots', id: 'shots' },
  { displayName: 'Shot Pct', id: 'shotPct' },
  { displayName: 'Two Point Shots', id: 'twoPointShots' },
  { displayName: 'Two Point Shot Pct', id: 'twoPointShotPct' },
  { displayName: 'SOG', id: 'shotsOnGoal' },
  { displayName: 'SOG Pct', id: 'shotsOnGoalPct' },
  { displayName: 'Two Point SOG', id: 'twoPointShotsOnGoal' },
  { displayName: 'Groundballs', id: 'groundBalls' },
  { displayName: 'Turnovers', id: 'turnovers' },
  { displayName: 'Caused Turnovers', id: 'causedTurnovers' },
  { displayName: 'Faceoff Pct', id: 'faceoffPct' },
  { displayName: 'Faceoffs Won', id: 'faceoffsWon' },
  { displayName: 'Faceoffs Lost', id: 'faceoffsLost' },
  { displayName: 'Saves', id: 'saves' },
  { displayName: 'Save Pct', id: 'savePct' },
  { displayName: 'SAA', id: 'saa' },
  { displayName: 'Penalties', id: 'numPenalties' },
  { displayName: 'PIM', id: 'pim' },
  { displayName: 'Two Point Goals Against', id: 'twoPointGoalsAgainst' },
  { displayName: 'Two Point GAA', id: 'twoPtGaa' },
  { displayName: 'Power Plays', id: 'timesManUp' },
  { displayName: 'Power Play Pct', id: 'powerPlayPct' },
  { displayName: 'Power Play Goals', id: 'powerPlayGoals' },
  { displayName: 'Power Play Shots', id: 'powerPlayShots' },
  { displayName: 'Times Short Handed', id: 'timesShortHanded' },
  { displayName: 'Penalty Kill Pct', id: 'manDownPct' },
  { displayName: 'Power Play Goals Against', id: 'powerPlayGoalsAgainst' },
  { displayName: 'Passes', id: 'totalPasses' }
]
