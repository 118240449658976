import React, { useState, useEffect, useContext, useCallback } from 'react'
import { BoxCon, BoxScoreBottomRow, GameCon } from '../styles'
import { timeStampToDate, getPeriodAndClockTime, ClockTime } from '../../../../Utils'
import type { EventGQL } from '../../../../Api'
import { BoxScoreAction } from './BoxScoreAction'
import { BoxScoreInfo } from './BoxScoreInfo'
import { GeneralTeamRow } from './Teams/GeneralTeamRow'
import { BoxScoreTeam } from './Teams/BoxScoreTeam'
import { TeamScore } from './Teams/TeamScore'
import { BoxScoreStatus } from './BoxScoreStatus'
import { Broadcasters } from './Broadcast/Broadcasters'
import { StatsContext } from '../../../../Context'

type EventProps = {
  eventId: string
  teamId?: string
}

export const EventBox: React.FC<React.PropsWithChildren<EventProps>> = ({ eventId }) => {
  const { events } = useContext(StatsContext)!
  const [event, setEvent] = useState<EventGQL | null>(null)
  let [date, setDate] = useState<string>('')
  let [clockTime, setClockTime] = useState<ClockTime | null>()

  useEffect(() => {
    const evForBox = events.find((ev) => ev.slugname === eventId)
    if (!evForBox) return
    setHumanDate(evForBox)
    if (evForBox.eventStatus === 1) {
      getInProgressGameInfo(evForBox)
    }
    setEvent(evForBox)
  }, [events])

  const setHumanDate = (ev: EventGQL) => {
    const time = timeStampToDate(ev?.startTime)
    setDate(time.date)
  }

  const getInProgressGameInfo = (liveGame: EventGQL) => {
    const periodAndTime = getPeriodAndClockTime(liveGame)
    if (!periodAndTime) return
    setClockTime(periodAndTime)
  }

  const getTeamScoreInfo = useCallback(
    (teamStatus: 'home' | 'away') => {
      return {
        scores: {
          home: event?.homeScore || 0,
          away: event?.visitorScore || 0,
        },
        eventStatus: event?.eventStatus || 0,
        teamStatus,
      }
    },
    [event]
  )

  if (!event) {
    return <div />
  }

  return (
    <BoxCon>
      <BoxScoreInfo event={event} date={date} />
      <GameCon>
        <div className="teamsAndScoresCol">
          <div className="teamAndScoreRow">
            <>
              {!event?.awayTeam?.officialId ? (
                <GeneralTeamRow />
              ) : (
                <BoxScoreTeam team={event.awayTeam} event={event} />
              )}
            </>
            {event?.eventStatus >= 1 && (
              <TeamScore scoreProps={getTeamScoreInfo('away')} />
            )}
          </div>

          <div className="teamAndScoreRow">
            <>
              {!event?.homeTeam?.officialId ? (
                <GeneralTeamRow />
              ) : (
                <BoxScoreTeam team={event.homeTeam} event={event} />
              )}
            </>
            {event?.eventStatus >= 1 && (
              <TeamScore scoreProps={getTeamScoreInfo('home')} />
            )}
          </div>
        </div>
        <BoxScoreStatus
          event={event}
          period={clockTime?.period}
          clockTime={clockTime?.clockTime}
        />
      </GameCon>
      <BoxScoreBottomRow>
        <Broadcasters event={event} />
        <BoxScoreAction event={event} buttonText="View Stats" />
        <BoxScoreAction event={event} buttonText="Preview" />
      </BoxScoreBottomRow>
    </BoxCon>
  )
}
