import { EventGQL, PlayerGameLogs, PlayLogs } from '../../Api'
import {
  createClearPlayLog,
  createFaceoffPlayLog,
  createGroundballPlayLog,
  createPenaltyPlayLog,
  createPowerPlayPlayLog,
  createShotClockExpPlayLog,
  createShotPlayLog,
  createTimeoutPlayLog,
  createTurnoverPlayLog,
} from '../../Components/GameComponents/PlayLogs/components/playLogHelpers'

//eventType for play logs
export const logEnums = {
  shot: 'shot',
  faceoff: 'faceoff'

}

export const filterPlayLogsByPeriod = (logs: PlayLogs[]) => {
  return {
    1: logs.filter(
      (pl) => pl.period === 1 && pl?.descriptor && pl.descriptor.includes('SHOT GOOD')
    ),
    2: logs.filter(
      (pl) => pl.period === 2 && pl?.descriptor && pl.descriptor.includes('SHOT GOOD')
    ),
    3: logs.filter(
      (pl) => pl.period === 3 && pl?.descriptor && pl.descriptor.includes('SHOT GOOD')
    ),
    4: logs.filter(
      (pl) => pl.period === 4 && pl?.descriptor && pl.descriptor.includes('SHOT GOOD')
    ),
    5: logs.filter(
      (pl) => pl.period > 4 && pl?.descriptor && pl.descriptor.includes('SHOT GOOD')
    ),
  }
}

export const createPlayLogDesc = (log: PlayLogs, event: EventGQL, showWinType: boolean = true, logType?: string) => {
  if(logType){
    log.eventType = logType
  }
  switch (log.eventType) {
    case 'shot':
      return createShotPlayLog(log, event)
    case 'faceoff':
      return createFaceoffPlayLog(log, showWinType)
    case 'groundball':
      return createGroundballPlayLog(log)
    case 'penalty':
      return createPenaltyPlayLog(log, event)
    case 'powerplayend':
      return createPowerPlayPlayLog(log)
    case 'turnover':
      return createTurnoverPlayLog(log, event)
    case 'shotclockexpired':
      return createShotClockExpPlayLog(log)
    case 'ballcleared':
      return createClearPlayLog(log)
    case 'timeoutcalled':
      return createTimeoutPlayLog(log)
    default:
      return log?.descriptor || ''
  }
}

export const convertEventToGameLogs = (playerLogs: PlayerGameLogs) => {
  return playerLogs.allEvents.map((log) => {
    const opponent =
      log.awayTeam.officialId === playerLogs.currentTeam.officialId
        ? log.homeTeam.officialId
        : log.awayTeam.officialId
    return {
      officialId: playerLogs.officialId,
      teamId: playerLogs.currentTeam.officialId,
      gamesPlayed: log.playerEventStats.gamesPlayed,
      goals: log.playerEventStats.goals,
      assists: log.playerEventStats.assists,
      points: log.playerEventStats.points,
      turnovers: log.playerEventStats.turnovers,
      shots: log.playerEventStats.shots,
      shotPct: log.playerEventStats.shotPct,
      shotsOnGoal: log.playerEventStats.shotsOnGoal,
      shotsOnGoalPct: log.playerEventStats.shotsOnGoalPct,
      gamesStarted: log.playerEventStats.gamesStarted,
      onePointGoals: log.playerEventStats.onePointGoals,
      twoPointGoals: log.playerEventStats.twoPointGoals,
      saves: log.playerEventStats.saves,
      savePct: log.playerEventStats.savePct,
      goalsAgainst: log.playerEventStats.goalsAgainst,
      twoPointGoalsAgainst: log.playerEventStats.twoPointGoalsAgainst,
      eventId: log.slugname,
      scheduledStartUtc: log.startTime,
      seasonSegment: log.seasonSegment === 'regular' ? 1 : 2,
      opponent: opponent,
    }
  })
}
