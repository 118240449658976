import { styled } from '@mui/system'
import { colors } from '../../../Constants/consts'
import { getTeamIc } from '../../../Components/TeamComponents/TeamImage/TeamIC'
import { CURRENT_YEAR } from '../../../Config/League/league.config'

type TeamImageStyleProps = {
  imgSize: 'xs' | 'sm' | 'md' | 'lg'
}

const TeamImgStyle = styled('img')<TeamImageStyleProps>(({ imgSize, theme }) => ({
  margin: theme.spacing(0),
  borderRadius: 5,
  width: imgSize === 'lg' ? 100 : imgSize === 'md' ? 50 : imgSize === 'sm' ? 30 : 22,
  [theme.breakpoints.between('md', 'lg')]: {
    width: imgSize === 'lg' ? 80 : imgSize === 'md' ? 40 : imgSize === 'sm' ? 25 : 20,
  },
  [theme.breakpoints.down('md')]: {
    width: imgSize === 'lg' ? 60 : imgSize === 'md' ? 30 : imgSize === 'sm' ? 18 : 14,
  },
}))

export const BackupImage = styled('div')<TeamImageStyleProps>(({ imgSize, theme }) => ({
  backgroundColor: colors.gray.gray40,
  margin: theme.spacing(1, 0),
  borderRadius: 5,
  width: imgSize === 'lg' ? 100 : imgSize === 'md' ? 50 : imgSize === 'sm' ? 30 : 22,
  height: imgSize === 'lg' ? 100 : imgSize === 'md' ? 50 : imgSize === 'sm' ? 30 : 22,
  [theme.breakpoints.between('md', 'lg')]: {
    width: imgSize === 'lg' ? 80 : imgSize === 'md' ? 40 : imgSize === 'sm' ? 25 : 20,
    height: imgSize === 'lg' ? 80 : imgSize === 'md' ? 40 : imgSize === 'sm' ? 25 : 20,
  },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0.5, 0),
    width: imgSize === 'lg' ? 60 : imgSize === 'md' ? 30 : imgSize === 'sm' ? 18 : 14,
    height: imgSize === 'lg' ? 60 : imgSize === 'md' ? 30 : imgSize === 'sm' ? 18 : 14,
  },
}))

type TeamImageParams = {
  imgSize: 'xs' | 'sm' | 'md' | 'lg'
  teamId: string
  teamSrc?: string
  year?: number
}
export const TeamImage: React.FC<TeamImageParams> = ({
  imgSize,
  teamId,
  teamSrc,
  year = CURRENT_YEAR,
}) => {
  if (teamSrc) {
    //Use passed in image
    return <TeamImgStyle imgSize={imgSize} src={teamSrc} alt={`${teamId} Logo`} />
  }
  const tm = getTeamIc(teamId, year)
  if (tm) {
    return <TeamImgStyle imgSize={imgSize} src={tm} alt={`${teamId} Logo`} />
  }
  return <BackupImage imgSize={imgSize} />
}

export default TeamImage
