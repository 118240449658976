export const teamAllDataQuery = `
query($id: ID!, $year: Int, $statsYear: Int, $eventsYear: Int, $includeChampSeries: Boolean!) {
  team(id:$id, forYear: $year) {
		officialId
    urlLogo
    location
    locationCode
    fullName
    league
    slogan
    teamLosses
    teamLossesPost
    teamTies
    teamTiesPost
    teamWins
    teamWinsPost
    allYears
    coaches{
			officialId
      coachType
      firstName
      lastName
    }
    events(year: $eventsYear) {
      id
      slugname
      externalId
      startTime
      week
      venue
      description
      location
      broadcaster
      eventStatus
      period
      homeScore
      visitorScore
      urlTicket
      urlPreview
      urlStreaming
      addToCalendarId
      gameStatus
      seasonSegment
      year
      waitlist
      waitlistUrl
      suiteId
      weekendTicketId
      awayTeam {
        officialId
        fullName
        teamWins
        teamLosses
      }
      homeTeam {
        officialId
        fullName
        teamWins
        teamLosses
      }
    }
    stats(year: $statsYear, segment:regular) {
      scores
      faceoffPct
      shotPct
      twoPointShotPct
      twoPointShotsOnGoalPct
      clearPct
      ridesPct
      savePct
      shortHandedPct
      shortHandedGoalsAgainstPct
      powerPlayGoalsAgainstPct
      manDownPct
      shotsOnGoalPct
      onePointGoals
      scoresAgainst
      saa
      powerPlayPct
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      shots
      twoPointShots
      twoPointShotsOnGoal
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      clears
      clearAttempts
      rides
      rideAttempts
      saves
      offsides
      shotClockExpirations
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedShotsAgainst
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      powerPlayShotsAgainst
      timesManUp
      timesShortHanded
      shotsOnGoal
      scoresPG
      shotsPG
      totalPasses
      touches
    }
    champSeries(year: $year) @include(if: $includeChampSeries) {
      teamWins
      teamLosses
      teamTies
      stats{
        scores
        faceoffPct
        shotPct
        twoPointShotPct
        twoPointShotsOnGoalPct
        clearPct
        ridesPct
        savePct
        shortHandedPct
        shortHandedGoalsAgainstPct
        powerPlayGoalsAgainstPct
        manDownPct
        shotsOnGoalPct
        onePointGoals
        scoresAgainst
        saa
        powerPlayPct
        gamesPlayed
        goals
        twoPointGoals
        assists
        groundBalls
        turnovers
        causedTurnovers
        faceoffsWon
        faceoffsLost
        faceoffs
        shots
        twoPointShots
        twoPointShotsOnGoal
        goalsAgainst
        twoPointGoalsAgainst
        numPenalties
        pim
        clears
        clearAttempts
        rides
        rideAttempts
        saves
        offsides
        shotClockExpirations
        powerPlayGoals
        powerPlayShots
        shortHandedGoals
        shortHandedShots
        shortHandedShotsAgainst
        shortHandedGoalsAgainst
        powerPlayGoalsAgainst
        powerPlayShotsAgainst
        timesManUp
        timesShortHanded
        shotsOnGoal
        scoresPG
        saa
        shotsPG
        totalPasses
        touches
      }
      roster {
        officialId
        slug
        profileUrl
        firstName
        lastName
        height
        age
        college
        collegeYear
        injuryStatus
        injuryDescription
        jerseyNum
        champSeries(year: $year) {
          year
          position
          positionName
          stats {
            GAA
            assists
            causedTurnovers
            faceoffPct
            faceoffs
            faceoffsLost
            faceoffsWon
            foRecord
            gamesPlayed
            gamesStarted
            goalieLosses
            goalieTies
            goalieWins
            goals
            goalsAgainst
            groundBalls
            numPenalties
            onePointGoals
            pim
            pimValue
            plusMinus
            points
            powerPlayGoals
            powerPlayGoalsAgainst
            powerPlayShots
            saa
            savePct
            saves
            scoresAgainst
            shortHandedGoals
            shortHandedGoalsAgainst
            shortHandedShots
            shotPct
            shots
            shotsOnGoal
            shotsOnGoalPct
            tof
            turnovers
            twoPointGoals
            twoPointGoalsAgainst
            twoPointShots
            twoPointShotPct
            twoPointShotsOnGoal
            twoPointShotsOnGoalPct
            twoPtGaa
            touches
            totalPasses
          }
        }
      }
    }
    postStats: stats(year: $statsYear, segment: post) {
      scores
      faceoffPct
      shotPct
      twoPointShotPct
      twoPointShotsOnGoalPct
      clearPct
      ridesPct
      savePct
      shortHandedPct
      shortHandedGoalsAgainstPct
      powerPlayGoalsAgainstPct
      manDownPct
      shotsOnGoalPct
      onePointGoals
      scoresAgainst
      saa
      powerPlayPct
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      shots
      twoPointShots
      twoPointShotsOnGoal
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      clears
      clearAttempts
      rides
      rideAttempts
      saves
      offsides
      shotClockExpirations
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedShotsAgainst
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      powerPlayShotsAgainst
      timesManUp
      timesShortHanded
      shotsOnGoal
      scoresPG
      shotsPG
      totalPasses
      touches
    }
    players {
      positionName
      officialId
      profileUrl
      jerseyNum
      firstName
      lastName
      lastNameSuffix
      position
      injuryStatus
      injuryDescription
      slug
      expFromYear
      stats(year: $statsYear, segment: regular) {
        points
        faceoffPct
        shotPct
        savePct
        shotsOnGoalPct
        onePointGoals
        scoresAgainst
        saa
        gamesPlayed
        goals
        twoPointGoals
        assists
        groundBalls
        turnovers
        causedTurnovers
        foRecord
        faceoffs
        shots
        goalsAgainst
        twoPointGoalsAgainst
        saves
       totalPasses
        touches
      }
      postStats: stats(year: $statsYear, segment: post) {
        points
        faceoffPct
        shotPct
        savePct
        shotsOnGoalPct
        onePointGoals
        scoresAgainst
        saa
        gamesPlayed
        goals
        twoPointGoals
        assists
        groundBalls
        turnovers
        causedTurnovers
        foRecord
        faceoffs
        shots
        goalsAgainst
        twoPointGoalsAgainst
        saves
        totalPasses
        touches
      }
    }
  }
}
`

export const fetchTeamInfo = `
query($id: ID, $year:Int) {
  team(id: $id, forYear:$year) {
    officialId
    location
    locationCode
    urlLogo
    slug
    year
    allYears
    urlLogo
    fullName
    league
    slogan
    teamWins
    teamLosses
    teamTies
    teamWinsPost
    teamLossesPost
    teamTiesPost
    coaches{
      coachType
      name
    }
  }
}
`

export const fetchTeamStats = `
query($id: ID, $year:Int, $segment: StatSegment!) {
  team(id: $id, forYear:$year) {
    stats(segment:$segment){
      scores
      faceoffPct
      shotPct
      twoPointShotPct
      twoPointShotsOnGoalPct
      clearPct
      ridesPct
      savePct
      shortHandedPct
      shortHandedGoalsAgainstPct
      powerPlayGoalsAgainstPct
      manDownPct
      shotsOnGoalPct
      onePointGoals
      scoresAgainst
      saa
      powerPlayPct
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      shots
      twoPointShots
      twoPointShotsOnGoal
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      clears
      clearAttempts
      rides
      rideAttempts
      saves
      offsides
      shotClockExpirations
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedShotsAgainst
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      powerPlayShotsAgainst
      timesManUp
      timesShortHanded
      shotsOnGoal
      totalPasses
      touches
    }
  }
}
`

export const fetchAllTeams = `
query($year: Int!, $sortBy: String, $includeChampSeries: Boolean!) {
  allTeams(year: $year, sortBy: $sortBy) {
    officialId
    locationCode
    location
    fullName
    urlLogo
    slogan
    teamWins
    teamLosses
    teamTies
    teamWinsPost
    teamLossesPost
    teamTiesPost
    league
    coaches {
      name
      coachType
    }
    stats(year: $year, segment:regular) {
      scores
      faceoffPct
      shotPct
      twoPointShotPct
      twoPointShotsOnGoalPct
      clearPct
      ridesPct
      savePct
      shortHandedPct
      shortHandedGoalsAgainstPct
      powerPlayGoalsAgainstPct
      manDownPct
      shotsOnGoalPct
      onePointGoals
      scoresAgainst
      saa
      powerPlayPct
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      shots
      twoPointShots
      twoPointShotsOnGoal
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      clears
      clearAttempts
      rides
      rideAttempts
      saves
      offsides
      shotClockExpirations
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedShotsAgainst
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      powerPlayShotsAgainst
      timesManUp
      timesShortHanded
      shotsOnGoal
      scoresPG
      shotsPG
      totalPasses
      touches
    }
    postStats: stats(year: $year, segment: post) {
      scores
      faceoffPct
      shotPct
      twoPointShotPct
      twoPointShotsOnGoalPct
      clearPct
      ridesPct
      savePct
      shortHandedPct
      shortHandedGoalsAgainstPct
      powerPlayGoalsAgainstPct
      manDownPct
      shotsOnGoalPct
      onePointGoals
      scoresAgainst
      saa
      powerPlayPct
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      shots
      twoPointShots
      twoPointShotsOnGoal
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      clears
      clearAttempts
      rides
      rideAttempts
      saves
      offsides
      shotClockExpirations
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedShotsAgainst
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      powerPlayShotsAgainst
      timesManUp
      timesShortHanded
      shotsOnGoal
      scoresPG
      shotsPG
      totalPasses
      touches
    }
    champSeries(year: $year) @include(if: $includeChampSeries) {
      teamWins
      teamLosses
      teamTies
      stats{
        scores
        faceoffPct
        shotPct
        twoPointShotPct
        twoPointShotsOnGoalPct
        clearPct
        ridesPct
        savePct
        shortHandedPct
        shortHandedGoalsAgainstPct
        powerPlayGoalsAgainstPct
        manDownPct
        shotsOnGoalPct
        onePointGoals
        scoresAgainst
        saa
        powerPlayPct
        gamesPlayed
        goals
        twoPointGoals
        assists
        groundBalls
        turnovers
        causedTurnovers
        faceoffsWon
        faceoffsLost
        faceoffs
        shots
        twoPointShots
        twoPointShotsOnGoal
        goalsAgainst
        twoPointGoalsAgainst
        numPenalties
        pim
        clears
        clearAttempts
        rides
        rideAttempts
        saves
        offsides
        shotClockExpirations
        powerPlayGoals
        powerPlayShots
        shortHandedGoals
        shortHandedShots
        shortHandedShotsAgainst
        shortHandedGoalsAgainst
        powerPlayGoalsAgainst
        powerPlayShotsAgainst
        timesManUp
        timesShortHanded
        shotsOnGoal
        scoresPG
        saa
        shotsPG
        totalPasses
        touches
      }
    }
  }
}
`
export const standingsQuery = `
query($year: Int!, $champSeries: Boolean!) {
  standings(season: $year, champSeries: $champSeries){
    team{
      officialId
      location
      locationCode
      urlLogo
      fullName
    }
    seed
    wins @skip(if: $champSeries)
    losses @skip(if: $champSeries)
    ties @skip(if: $champSeries)
    scores @skip(if: $champSeries)
    scoresAgainst @skip(if: $champSeries)
    scoreDiff @skip(if: $champSeries)
    csWins @include(if: $champSeries)
    csLosses @include(if: $champSeries)
    csTies @include(if: $champSeries)
    csScores @include(if: $champSeries)
    csScoresAgainst @include(if: $champSeries)
    csScoreDiff @include(if: $champSeries)
    conferenceWins
    conferenceLosses
    conferenceTies
    conferenceScores
    conferenceScoresAgainst
    conference
    conferenceSeed
  }
}
`
