export const initializeSentry = async () => {
  if (process.env.NODE_ENV === 'production') {
    try {
      const Sentry = await import('@sentry/browser')
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN || '',
        stackParser: Sentry.defaultStackParser,
        tracesSampleRate: 0.1,
        profilesSampleRate: 0.2,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.5,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.breadcrumbsIntegration(),
          Sentry.browserProfilingIntegration(),
          Sentry.sessionTimingIntegration(),
          Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
          })
        ],
        environment: 'production',
      })
    } catch (err) {
      console.error('Failed to initialize Sentry:', err)
      throw err
    }
  }
}
