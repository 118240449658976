import { logEvent } from '../analytics/firAnalytics'

export const sendVideoStart = (params: any) => {
  logEvent('video_start', params)
}

export const sendVideoComplete = (params: any) => {
  logEvent('video_watched', {
    percentage_watched: '1',
    ...params,
  })
}

export const sendVideoPause = (params: any) => {
  logEvent('video_watched', params)
}

export const videoLog = (name: string, params: any) => {
  logEvent(name, params)
}

export const calcDimensionsFromAspectRatio = (
  aspectRatio: string,
  height: number,
  isMobile: boolean
) => {
  let updatedHeight = height
  switch (aspectRatio) {
    case '16:9':
      updatedHeight = isMobile ? height - 60 : height
      return (updatedHeight / 9) * 16
    case '4:3':
      updatedHeight = isMobile ? height - 40 : height
      return (updatedHeight / 3) * 4
    case '1:1':
      updatedHeight = isMobile ? height - 25 : height
      return updatedHeight
    case '3:4':
      return (height / 4) * 3
    case '9:16':
      return (height / 16) * 9
    case '4:5':
      return (height / 5) * 4
    default:
      return (height / 9) * 16
  }
}
