import { colors, fontSizes } from '../../Constants/consts'
import { styled } from '@mui/system'
import { MuiTelInput, TextField } from '../LazyComponents'

export const ModalFrame = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  boxShadow: '1px 2px 20px 5px #CECECE',
  background: colors.white,
  borderRadius: 15,
}))

export const AuthCon = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(1),
}))

export const LoginIconRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  margin: theme.spacing(0, 3),
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(2),
  },
  '& .iconCon': {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '50%',
    background: colors.gray.gray20,
    padding: theme.spacing(1, 1),
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 1),
    },
    '& .icImage': {
      fontSize: fontSizes.large,
    },
  },
}))

export const InputStyle = styled(TextField)<LoginProps>(({ uiTheme, theme }) => ({
  width: 350,
  borderRadius: '5px',
  margin: theme.spacing(1),
  '& label.Mui-focused': {
    color: colors.blue,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.blue,
  },
  '& .MuiOutlinedInput-root': {
    background: uiTheme === 'dark' ? colors.gray.gray70 : colors.offWhite,
    height: 50,
    [theme.breakpoints.down('md')]: {
      height: 45,
    },
    '& fieldset': {
      borderColor: uiTheme === 'dark' ? colors.gray.gray70 : colors.gray.gray20,
    },
    '&:hover fieldset': {
      borderColor: colors.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.white,
    },
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '85vw',
  },
}))

export const StyledMuiTelInput = styled(MuiTelInput)(({ theme }) => ({
  width: 300,
  borderRadius: '5px',
  margin: theme.spacing(0, 1, 1, 1),
  '& label.Mui-focused': {
    color: colors.blue,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.blue,
  },
  '& .MuiOutlinedInput-root': {
    background: colors.offWhite,
    height: 50,
    [theme.breakpoints.down('md')]: {
      height: 45,
    },
    '& fieldset': {
      borderColor: colors.gray.gray20
    },
    '&:hover fieldset': {
      borderColor: colors.blue,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.blue,
    },
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '85vw',
  },
}))

export const CancelRow = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginTop: 16,
  '& .cancelBtn': {
    padding: '3px 16px',
    borderRadius: 2,
    border: 0,
    backgroundColor: colors.white,
    color: colors.text,
    fontWeight: 'bold',
    width: '100%',
    maxWidth: 220,
    minHeight: 30,
    cursor: 'pointer',
  },
}))

export const ErrorStyle = styled('div')(({ theme }) => ({
  color: '#cc0033',
    display: 'inline-block',
    fontSize: '12px',
    lineHeight: '15px',
    margin: '2px',
}))

type LoginProps = {
  uiTheme: 'light' | 'dark'
}

export const LoginStyle = styled('div')<LoginProps>(({ uiTheme, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  margin: theme.spacing(3, 1),
  background: uiTheme === 'dark' ? colors.darkBackground : colors.offWhite,
  '& .loginTitle': {
    fontSize: fontSizes.semiLarge,
    color: uiTheme === 'dark' ? colors.white : colors.text,
    fontWeight: 'bold',
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: fontSizes.medium,
    },
  },
  '& .loginDesc': {
    fontSize: fontSizes.medium,
    color: uiTheme === 'dark' ? colors.white : colors.gray.gray70,
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('md')]: {
      fontSize: fontSizes.small,
    },
  },
  '& .loginBtn': {
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: theme.spacing(1),
    border: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: colors.black,
    backgroundColor: colors.yellow,
    cursor: 'pointer',
    width: 250,
    '&:hover': {
      backgroundColor: colors.blue,
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(0.5),
    },
  },
}))

export const MoreDataCon = styled('div')<LoginProps>(({ uiTheme, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(3, 1),
  minHeight: 400,
  '& .submitBtn': {
    cursor: 'pointer',
    margin: theme.spacing(2),
    padding: theme.spacing(0.5, 3),
    borderRadius: theme.spacing(3),
    fontSize: fontSizes.semiLarge,
    fontWeight: 'bold',
    color: uiTheme === 'dark' ? colors.black : colors.white,
    backgroundColor: uiTheme === 'dark' ? colors.white : colors.black,
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: fontSizes.small,
    },
  },
  '& .multiInputCon': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 400,
    margin: theme.spacing(2, 1),
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
    },
  },
  '& .inputCon': {
    margin: theme.spacing(2, 1),
  },
}))
