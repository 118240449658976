import React, { useState } from 'react'
import { SubDropdown } from './styles'

type SubmenuPropTypes = {
  menuText: string
  options: any[]
}

export const SubMenu: React.FC<SubmenuPropTypes> = ({
  menuText,
  options,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <li>
      <button
        onClick={() => setIsOpen(!isOpen)}
        >{menuText}</button>
      {isOpen &&
        <SubDropdown>
          {options.map((o) =>
            <li key={o.linkText}>
              <a href={o.link}>{o.linkText}</a>
            </li>
          )}
        </SubDropdown>
      }
    </li>
  )
}
