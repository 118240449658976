import React, { createContext, useContext, useState } from 'react'
import { FeedItem, sendGamificationEngagement } from '../../Api'
import { Overlay } from '../../Components/Layout'
import {
  sendVideoComplete,
  sendVideoPause,
  sendVideoStart,
} from '../../Utils/Video/videoUtils'

import { VideoPlayer } from '../../Components/Videos/VideoPlayer'
import { logBrazeEvent } from '../../Utils/analytics/brazeAnalytics'
import { AuthContext } from '../../Api/firebase'
import { gamificationEngagements } from '../../Constants/gamification'
import { DEFAULT_VIDEO_PLAYER_ID } from '../../Config/Video/config'
import { getVideoPlayerOptions } from '../../Constants/video'

type VideoContextType = {
  loadAndShowVideo: (mediaItem: FeedItem) => void
  loading: boolean
}

export const VideoContext = createContext<VideoContextType | null>(null)

export const VideoProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isLoggedIn, uid } = useContext(AuthContext)!
  const [mediaItem, setMediaItem] = useState<FeedItem | null>(null)
  const [showVideo, setShowVideo] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [watchCredit, setWatchCredit] = useState<boolean>(false)
  // ANALYTICS  //
  const [videoPlayCount, setVideoPlayCounter] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)

  const loadAndShowVideo = (media: FeedItem) => {
    setMediaItem(media)
    setDuration(media?.duration || 0)
    setShowVideo(!showVideo)
  }
  const handleClose = () => {
    setMediaItem(null)
    watchCredit && setWatchCredit(false)
    setShowVideo(!showVideo)
  }

  // ANALYTICS  //
  const onVideoComplete = () => {
    let params = {
      videoLength: duration > 0 ? duration : '',
      mediaId: mediaItem?.mediaId,
    }
    sendVideoComplete(params)
  }

  const onVideoStart = () => {
    if (videoPlayCount > 0) return
    let params = {
      videoLength: duration > 0 ? duration : '',
      mediaId: mediaItem?.mediaId,
    }
    setVideoPlayCounter(1)
    sendVideoStart(params)
    if (isLoggedIn && uid) {
      logBrazeEvent(gamificationEngagements.VIDEO_WATCH)
      if (!watchCredit && mediaItem?.mediaId) {
        setWatchCredit(true)
        Promise.resolve(
          sendGamificationEngagement(
            uid,
            gamificationEngagements.VIDEO_WATCH,
            mediaItem.mediaId
          )
        )
      }
    }
  }

  const onVideoPause = (watchedTime: number) => {
    if (watchedTime === 0 || duration === 0) return
    let pctWatched = watchedTime / duration
    let params = {
      mediaId: mediaItem?.mediaId,
      seconds_watched: watchedTime,
      percentage_watched: pctWatched,
    }
    sendVideoPause(params)
  }

  return (
    <VideoContext.Provider
      value={{
        loading: loading,
        loadAndShowVideo: loadAndShowVideo,
      }}
    >
      {children}
      {showVideo && mediaItem && (
        <Overlay type="video" onClick={handleClose}>
          <VideoPlayer
            key={mediaItem.mediaId}
            playerId={DEFAULT_VIDEO_PLAYER_ID}
            videoId={mediaItem?.mediaId}
            options={getVideoPlayerOptions({
              autoplay: true,
              aspectRatio: mediaItem?.aspectRatio,
              muted: false,
            })}
            onVideoStart={onVideoStart}
            onVideoComplete={onVideoComplete}
            onVideoPause={onVideoPause}
          />
        </Overlay>
      )}
    </VideoContext.Provider>
  )
}
