import React, { createContext, useState, useEffect } from 'react'
import {
  EventGQL,
  fetchEventByIdGQL,
  fetchLiveEventByIdGQL,
  fetchPlayLogsByEvent,
  PlayLogs,
} from '../../Api'

type BroadcastGameContextType = {
  gameEvent: EventGQL | null
  playLogs: PlayLogs[]
  isEventLoading: boolean
  isLoading: boolean
  fetchPlayLogs: (event: EventGQL) => Promise<void>
}

export const BroadcastGameContext = createContext<BroadcastGameContextType | null>(null)

export const BroadcastGameProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [gameEvent, setGameEvent] = useState<EventGQL | null>(null)
  const [playLogs, setPlayLogs] = useState<PlayLogs[]>([])
  const [isEventLoading, setIsEventLoading] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    let slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    const id = slug.split('?')[0]
    fetchEvent(id)
  }, [])

  const fetchEvent = async (id: string) => {
    try {
      const ev = await fetchEventByIdGQL(id)
      setGameEvent(ev)
      setIsEventLoading(false)
      await checkLiveGame(ev)
      return
    } catch (err) {
      console.error(err)
      setIsEventLoading(false)
      return
    }
  }

  const checkLiveGame = async (ev: EventGQL) => {
    if (!ev) {
      return
    }
    await fetchPlayLogs(ev)
    if (ev?.eventStatus === 1 || isWithin30Mins(ev)) {
      const liveEv = await fetchLiveEventByIdGQL(ev.slugname)
      if (!liveEv) return
      setGameEvent(liveEv)
      fetchPlayLogs(liveEv)
      const interval = setInterval(() => {
        //Starting interval to go every 3 seconds
        console.log('Getting Live event')
        fetchLiveEventByIdGQL(ev.slugname).then((ev) => {
          if (!ev) {
            clearInterval(interval)
            return
          }
          if (ev?.eventStatus >= 2) {
            setGameEvent(ev)
            clearInterval(interval)
            return
          }

          setGameEvent(ev)
          fetchPlayLogs(ev)
        })
      }, 3000)
      return () => clearInterval(interval)
    }
    return
  }

  const isWithin30Mins = (ev: EventGQL) => {
    //Checking draft status
    const gameStart = ev.startTime
    let now = Date.now() / 1000
    const diff = gameStart - now
    if (diff < 1800 && diff > -1800) {
      //Less than 30 mins till game or within 30 mins of game start
      console.log('Within 30 mins of game')
      return true
    } else {
      return false
    }
  }

  const fetchPlayLogs = async (event: EventGQL) => {
    if (gameEvent?.eventStatus === 1 && gameEvent?.id === event.id) {
      await fetchNewPlayLogs(event)
      return
    }

    if (
      playLogs.length > 0 &&
      gameEvent?.eventStatus !== 1 &&
      gameEvent?.id === event.id
    ) {
      setIsLoading(false)
      return Promise.resolve()
    } else {
      //Need to get play logs from DB
      await fetchNewPlayLogs(event)
      return
    }
  }

  const fetchNewPlayLogs = async (event: EventGQL) => {
    try {
      const logs = await fetchPlayLogsByEvent(event.externalId)
      if (!logs || logs.length === 0) {
        setIsLoading(false)
        return []
      }
      const filtered = logs.filter(
        (l) =>
          !l.markerId.includes('-G') &&
          l.eventType !== 'periodend' &&
          l.eventType !== 'start' &&
          l.eventType !== 'periodstart'
      )
      setPlayLogs(filtered)
      setIsLoading(false)
      setGameEvent(event)
      return
    } catch (err) {
      console.log(err)
      return
    }
  }

  return (
    <BroadcastGameContext.Provider
      value={{
        gameEvent: gameEvent,
        playLogs: playLogs,
        isEventLoading: isEventLoading,
        isLoading: isLoading,
        fetchPlayLogs: fetchPlayLogs,
      }}
    >
      {children}
    </BroadcastGameContext.Provider>
  )
}
