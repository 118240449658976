import React, { createElement, Fragment, useContext, useEffect, useRef } from 'react'
import { autocomplete } from '@algolia/autocomplete-js'
import { SearchConStyle, SearchStyle } from './styles'
import { SEARCH_CONFIG } from '../../Config/Search/search.config'
import { appId, appKey } from '../../Api/search/Algolia'
import { createAlgoliaInsightsPlugin } from '@algolia/autocomplete-plugin-algolia-insights'
import insightsClient from 'search-insights'
import { AuthContext } from '../../Api/firebase'
import { logEvent } from '../../Utils'
import { createRoot } from 'react-dom/client'

export function Autocomplete(props: any) {
  const containerRef = useRef<any | null>(null)
  const panelRootRef = useRef<any | null>(null)
  const rootRef = useRef<any | null>(null)
  const { uid } = useContext(AuthContext)!

  insightsClient('init', { appId, apiKey: appKey })
  if (uid) {
    insightsClient('setUserToken', uid)
  }
  const algoliaInsightsPlugin = createAlgoliaInsightsPlugin({
    insightsClient,
    onSelect({ insights, insightsEvents }) {
      const events = insightsEvents.map((insightsEvent) => {
        switch (insightsEvent.index) {
          case `${SEARCH_CONFIG.pll}`: {
            logEvent('pll_search_click', insightsEvent)
            return {
              ...insightsEvent,
              eventName: `pll_search_click`,
            }
          }
          case 'shopify_products': {
            logEvent('shopify_seach_click', insightsEvent)
            return {
              ...insightsEvent,
              eventName: 'shopify_seach_click',
            }
          }
          default: {
            return {
              ...insightsEvent,
              eventName: `${insightsEvent.index} Selected`,
            }
          }
        }
      })
      insights.clickedObjectIDsAfterSearch(...events);
    },
  })

  useEffect(() => {
    if (!containerRef.current) {
      return undefined
    }

    const search = autocomplete({
      container: containerRef.current || '',
      placeholder: "Search",
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }: any, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount()
          panelRootRef.current = createRoot(root)
        }

        panelRootRef.current.render(children)
      },
      plugins: [algoliaInsightsPlugin],
      ...props,
    })

    return () => {
      search.destroy()
    }
  }, [])

  return (
    <SearchConStyle>
      <SearchStyle ref={containerRef} />
    </SearchConStyle>
  )
}
