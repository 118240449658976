import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import DefaultPage from '../../Pages/DefaultPage/DefaultPage'

const BroadcastGameProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.BroadcastGameProvider,
  }))
)

export const BroadcastViewerContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<DefaultPage />}>
      <BroadcastGameProvider>
        <Outlet />
      </BroadcastGameProvider>
    </Suspense>
  )
}
