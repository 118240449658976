import {
  PLL_LINKS,
  CNBC_IMG,
  BROADCAST_IMG,
  REVERSE_BROADCAST_IMG,
  Dropdown,
} from '../../Config/League/league.config'
import type { EventGQL } from '../../Api'
import Constants from '../../Context/Constants'
import { createMatchupPreviewDynamicLink } from '../dynamicLinks/dynamicLinkHelpers'
import { createTicketUTM } from '../../Routing'
import { months, shortDays } from '../../Config/League/league.config'

type EventWeekParams = {
  week: string
  events: EventGQL[]
}

export type WeekTicketData = {
  week: string
  tickets: string
  dayTickets: string[]
  suitesId: string
  waitlistUrl: string
  waitlist: boolean
}

export type ClockTime = {
  period: string
  clockTime: string
}

export const gameLeaderStats = ['points', 'faceoffsWon', 'groundBalls', 'saves']

const GamePeriodMap: { [key: string]: string } = {
  '1': '1st',
  '2': '2nd',
  '3': '3rd',
  '4': '4th',
}

/**
 *
 * Map events into specific weeks
 */
export const mapEventWeeks = ({ week, events }: EventWeekParams) => {
  const filteredEvents = events.filter((event) => event.week === week)
  return filteredEvents
}

/**
 *
 * Map events into specific weeks
 */
export const getWeekList = (events: EventGQL[]) => {
  let weekList: string[] = []
  events.map((e) => {
    let we = weekList.find((w) => w === e.week)
    if (!we) {
      //Week wasn't added yet
      weekList.push(e.week)
    }
    return
  })
  return weekList
}

/**
 *
 * Return list of dropdown weeks using events
 */
export const createWeekDropdown = (events: EventGQL[]) => {
  let weekList: Dropdown[] = [
    {
      label: 'All',
      value: 'all',
    },
  ]
  events.map((e) => {
    let we = weekList.find((w) => w.value === e.week)
    if (!we) {
      //Week wasn't added yet
      weekList.push({ label: `Week ${e.week}`, value: e.week })
    }
    return
  })
  return weekList
}

/**
 *
 * Return date range of all games in a specific weekend
 */
export const getWeekendRange = (events: EventGQL[]) => {
  if (events.length === 0) return
  // Only one event, grab the date
  if (events.length === 1) return getShortDate(events[0].startTime)
  const sorted = events.sort((a, b) => a.startTime - b.startTime)
  const [first] = sorted.slice(0)
  const [last] = sorted.slice(-1)
  const firstDay = getShortDate(first.startTime)
  const lastDay = getShortDate(last.startTime)
  return firstDay === lastDay ? firstDay : `${firstDay}${` - ${lastDay}`}`
}

/**
 *
 * Shifting events that already happened to the back
 */
export const setTickerStart = (events: EventGQL[]) => {
  const start = events.find((ev) => ev.eventStatus < 2)
  if (!start) {
    //last Item
    return events.length - 1
  }
  return start.gameNumber - 1
}

/**
 *
 * Return previewUrl for specific event
 */
export const getPreviewUrl = (slug: string) => {
  if (slug.includes('all-star')) {
    return PLL_LINKS.allstar
  } else {
    return createMatchupPreviewDynamicLink(slug)
  }
}

/**
 *
 * Add utm to day ticks
 */
export const createDayTicketUrl = (
  ticketUrl: string,
  page: string,
  week: string,
  day: string = ''
) => {
  if (!page) return ''
  return createTicketUTM({
    ticketId: ticketUrl,
    medium: `${page}_Page`,
    campaign: `${page}_Sale`,
    page,
    week,
    day,
  })
}

/**
 *
 * Get weekends that are special like Opening Weekend and Playoffs
 */
export const getSpecialWeek = (week: string) => {
  switch (week) {
    case '1':
      return 'Opening Weekend'
    case '6':
      return 'All Star Weekend'
    case '9':
      return 'Quarterfinals'
    case '10':
      return 'Semifinals'
    case '11':
      return 'Championship'
    default:
      return
  }
}

/**
 *
 * Return all broadcaster images for specific event
 */
export const getBroadcastImg = (broadcasters: string[]) => {
  const broadcastImgs = broadcasters.map((name) => {
    switch (name) {
      case 'ESPN':
        return `${BROADCAST_IMG}/${name}_Main.png`
      case 'ESPN2':
        return `${BROADCAST_IMG}/${name}_Main.png`
      case 'ABC':
        return `${BROADCAST_IMG}/${name}_Main.png`
      case 'ESPNU':
        return `${BROADCAST_IMG}/${name}_Black.png`
      case 'ESPN+':
        return `${BROADCAST_IMG}/${name.replace('+', '%2B')}_Black.png`
      case 'Peacock':
        return `${BROADCAST_IMG}/${name}_Black.png`
      case 'NBC':
        return `${BROADCAST_IMG}/${name}_Black.png`
      case 'CNBC':
        return `${CNBC_IMG}/${name}.png`
      default:
        return ''
    }
  })
  return broadcastImgs
}

/**
 *
 * Return all broadcaster images for specific event
 */
export const getReverseBroadcastImg = (broadcasters: string[]) => {
  const broadcastImgs = broadcasters.map((name) =>
    name === ''
      ? ''
      : name === 'CNBC'
      ? `${CNBC_IMG}/${name}.png`
      : name === 'ABC'
      ? `${REVERSE_BROADCAST_IMG}/${name.replace('+', '%2B')}_Main.png`
      : `${REVERSE_BROADCAST_IMG}/${name.replace('+', '%2B')}_White.png`
  )
  return broadcastImgs
}

/**
 *
 * Return team cal object that contains calendar Id
 */
export const getTeamCalId = (team: string) => {
  const obj = teamCalendarIds.find((t) => t.team === team)
  if (!obj) {
    return allAddEventsId
  }
  return obj.calId
}
/**
 *
 * Returning shortened date from timestamp
 */
export const getShortDate = (timeStamp: any) => {
  const months = Constants.months
  const s = new Date(timeStamp * 1000)
  const m = months[s.getMonth()]
  const date = s.getDate()
  const dateString = `${m || ''} ${date || ''}`
  return dateString
}

/**
 *
 * Returning long date from timestamp ie Thursday, May 1 7pm ET
 */
export const timeStampToDate = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  const dateStr = s.toLocaleTimeString('en-US')

  // get just the time
  const tm = dateStr.slice(0, -3)

  // remove extra :00 if exists
  let st = tm.includes(':00') ? tm.split(':00')[0] : tm

  // 3am is the time set for a game that doesn't have a time, return TBD
  if (st.includes(':23')) {
    st = 'TBA'
  }

  //check if am or pm
  const ampm = dateStr.includes('PM') ? 'pm' : 'am'

  const dateAndTime = {
    date: `${shortDays[s.getDay()]}, ${months[s.getMonth()]} ${s.getDate()}`,
    time: `${st}${ampm}`,
  }
  return dateAndTime
}

/**
 *
 * Returning game time in user's local time
 */
export const getGameTime = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  const dateStr = s.toLocaleTimeString('en-US')

  // get just the time
  const tm = dateStr.slice(0, -3)

  // remove extra :00 if exists
  const st = tm.includes(':00') ? tm.split(':00')[0] : tm

  // 3am is the time set for a game that doesn't have a time, return TBD
  if (st.includes(':23')) return 'TBA'

  //check if am or pm
  const ampm = dateStr.includes('PM') ? 'pm' : 'am'

  return `${st}${ampm}`
}

/**
 *
 * Return the period and clock time of a specific game
 */
export const getPeriodAndClockTime = (event: EventGQL) => {
  if (!event) return
  let period: string | number = ''
  if (event.period > 5) {
    const otNumber = event.period - 4 //If second overtime, event.period would be 6
    period = `OT${otNumber}`
  } else {
    period = event.period === 5 ? 'OT' : GamePeriodMap[event.period]
  }
  const clockSecs = formatClockSeconds(event?.clockSeconds)

  if (event?.period === 1 && event?.clockMinutes === 0 && event?.clockSeconds === 0) {
    return { period: 'End of 1st', clockTime: '' }
  }

  if (event?.period === 2 && event?.clockMinutes === 0 && event?.clockSeconds === 0) {
    return { period: 'Half', clockTime: '' }
  }

  if (event?.period === 3 && event?.clockMinutes === 0 && event?.clockSeconds === 0) {
    return { period: 'End of 3rd', clockTime: '' }
  }

  if (
    event?.period === 4 &&
    event?.clockMinutes === 0 &&
    event?.clockSeconds === 0 &&
    event?.visitorScore === event.homeScore
  ) {
    return { period: 'End of Reg', clockTime: '' }
  }

  let clockTime = ''
  clockTime = `${event?.clockMinutes || 0}:${clockSecs}`
  if (
    event?.clockMinutes !== 0 &&
    !event?.clockMinutes &&
    event?.clockSeconds !== 0 &&
    !event?.clockSeconds
  ) {
    clockTime = ``
  }
  return {
    period,
    clockTime,
  }
}

export const formatClockSeconds = (seconds?: number | null) => {
  if (seconds === undefined || seconds === null) return ''
  return seconds === 0 ? '00' : seconds < 10 ? `0${seconds}` : `${seconds}`
}

export const eventWeeks: string[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
]

export const allAddEventsId = 'BR334295'
export const teamCalendarIds = [
  {
    team: 'All Teams',
    calId: 'BR334295',
  },
  {
    team: 'Archers',
    calId: 'qU270710',
  },
  {
    team: 'Atlas',
    calId: 'py270705',
  },
  {
    team: 'Cannons',
    calId: 'hf340635',
  },
  {
    team: 'Chaos',
    calId: 'yN270706',
  },
  {
    team: 'Chrome',
    calId: 'fe270704',
  },
  {
    team: 'Redwoods',
    calId: 'Nc270565',
  },
  {
    team: 'Waterdogs',
    calId: 'Zg270709',
  },
  {
    team: 'Whipsnakes',
    calId: 'hp270707',
  },
]

export const showTicker = (url: string) => {
  if (
    url.includes('/games') ||
    url.includes('/career-leaders') ||
    url.includes('/advanced-stats')
  ) {
    return false
  }
  return true
}

/**
 *
 * Return if regseason has started or not
 */
export const hasRegularSeasonStarted = (events: EventGQL[]) => {
  if (events.length < 1) return false
  const regGames = events.filter((ev) => ev.seasonSegment === 'regular')
  if (regGames.length < 1) return false

  const sorted = regGames.sort((a, b) => a.startTime - b.startTime)
  const now = new Date()
  //Set current time to seconds
  const currentTime = now.getTime() / 1000
  const diff = sorted[0].startTime - currentTime
  if (diff < 0 || sorted[0].eventStatus > 0) {
    // First reg season game has started
    return true
  } else {
    return false
  }
}

/**
 *
 * Check if game is live
 */
export const isLiveGame = (ev: EventGQL) => {
  if(!ev?.startTime) return false
  const now = new Date()
  //Set current time to seconds
  const currentTime = now.getTime() / 1000
  //current time > start time = started
  const startStatus = ev.startTime - currentTime

  const hasEnded = ev.eventStatus >= 2

  if (startStatus < 1 && !hasEnded) return true
  return false
}
