export type StorageOptionsStatus = {
  indexedDB: boolean
  local: boolean
  session: boolean
  cookies: boolean
}

//Cached storage names
export const STORAGE_NAMES = {
  seasonEvents: 'pll_season_events',
  standings: 'standings',
  season_players: 'season_players',
  league_player_leaders: 'pll_league_ply_ldrs',
  career_player_leaders: 'career_ply_ldrs'
}
