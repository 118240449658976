import { ConvertStatValue } from '../formatting'
import type { PlayerData, PlayersStatsData, Positions } from '../../Api'
import { Season } from '../../Context/SeasonContext/SeasonContext'

type PackedMap = { [key: string]: [string, string, string] }
export type StatListHeader =
  | 'ByPlayerPosition'
  | 'ByTeamPlayers'
  | 'GamePlayerGroups'
  | 'ByTeamGroups'
  | 'ChampSeriesByPlayerPosition'

// Stat mapping
// [short name, long name, field name]
export const statFields: PackedMap = {
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '1ptG': ['1pt G', '1Pt Goals', 'onePointGoals'],
  '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
  '2ptG': ['2pt G', '2Pt Goals', 'twoPointGoals'],
  '2PtGA': ['2Pt GA', '2Pt Goals Against', 'twoPointGoalsAgainst'],
  '2PtSh': ['2Pt Sh', '2Pt Shots', 'twoPointShots'],
  '2PtSh%': ['2Pt Sh%', '2Pt Shot %', 'twoPointShotPct'],
  A: ['A', 'Assists', 'assists'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  FO: ['FO', 'Faceoffs', 'foRecord'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  GP: ['GP', 'Games Played', 'gamesPlayed'],
  P: ['P', 'Points', 'points'],
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pimValue'],
  PowG: ['PowG', 'Power Play Goals', 'powerPlayGoals'],
  SA: ['SA', 'Scores Against', 'scoresAgainst'],
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  TCH: ['TCH', 'Touches', 'touches'],
  PAS: ['PAS', 'Total Passes', 'totalPasses'],
}
// type StatKeys = keyof typeof statFields

type StatListType = {
  career: string[]
  season: string[]
  game: string[]
  fav: string[]
}
export type StatTypes = keyof StatListType

// FOR POSTIONS: M and A and F
const offense: StatListType = {
  career: ['GP', 'P', '1G', '2G', 'A', 'Sh%', 'GB', 'PowG', 'TO'],
  season: [
    'GP',
    'P',
    '1G',
    '2G',
    'A',
    'Sh',
    'Sh%',
    '2PtSh',
    '2PtSh%',
    'SOG',
    'SOG%',
    'GB',
    'TO',
    'PowG',
    'Pen',
    'PIM',
  ],
  game: ['P', '1G', '2G', 'A', 'Sh', 'Sh%', 'SOG', 'GB', 'TO', 'TCH', 'PAS'],
  fav: ['P', '1G', '2G', 'A'],
}

/// SSDM, LSM and D
const defense: StatListType = {
  career: ['GP', 'CT', 'GB', 'P', '1G', '2G', 'A', 'PIM'],
  season: [
    'GP',
    'CT',
    'GB',
    'P',
    '1G',
    '2G',
    'A',
    'Sh',
    'Sh%',
    '2PtSh',
    '2PtSh%',
    'TO',
    'Pen',
    'PIM',
  ],
  game: ['CT', 'GB', 'P', 'Pen', 'Sh', 'SOG', 'TCH', 'PAS'],
  fav: ['CT', 'GB', 'P'],
}

// FOR POSTIONS: FO
const faceoff: StatListType = {
  career: ['GP', 'FO', 'FO%', 'P', '1ptG', '2ptG', 'A', 'GB'],
  season: [
    'GP',
    'FO',
    'FO%',
    'P',
    '1ptG',
    '2ptG',
    'A',
    'GB',
    'Sh',
    'Sh%',
    '2PtSh',
    'TO',
    'Pen',
    'PIM',
  ],
  game: ['FO', 'FO%', 'P', 'A', 'GB',  'Sh', 'SOG', 'TCH', 'PAS'],
  fav: ['FO%', 'P', 'GB'],
}

// FOR POSTIONS: G
const goalie: StatListType = {
  career: ['GP', 'Sv%', 'Sv', 'SAA', '2PtGA', 'P', '1ptG', '2ptG', 'A', 'GB'],
  season: ['GP', 'Sv%', 'Sv', 'SAA', 'SA', '2PtGA', 'P', 'CT', 'GB', 'TO', 'PIM'],
  game: ['SA', 'Sv%', 'Sv'],
  fav: ['Sv%', 'Sv'],
}

// Stat mapping
// [short name, long name, field name]
export const offensiveSeasonStatsMap: PackedMap = {
  P: ['P', 'Points', 'points'],
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
  A: ['A', 'Assists', 'assists'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  TCH: ['TCH', 'Touches', 'touches'],
  TO: ['TO', 'Turnovers', 'turnovers'],

  // CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
}

// [short name, long name, field name]
export const defensiveSeasonStatsMap: PackedMap = {
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  P: ['P', 'Points', 'points'],
  A: ['A', 'Assists', 'assists'],
}

// [short name, long name, field name]
export const faceoffSeasonStatsMap: PackedMap = {
  FO: ['FO', 'Faceoffs', 'foRecord'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  P: ['P', 'Points', 'points'],
}

export const goalieSeasonStatsMap: PackedMap = {
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
}

// [short name, long name, field name]
export const getFantasyStatsMap = (week: number | null): PackedMap  => {

return {
  GP: ['GP', 'Games Played', 'gamesPlayed'],
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
  A: ['A', 'Assists', 'assists'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  FOW: ['FOW', 'Faceoffs Won', 'faceoffsWon'],
  FOL: ['FOL', 'Faceoffs Lost', 'faceoffsLost'],
  Sv: ['Sv', 'Saves', 'saves'],
  GA: ['GA', 'Goals Against', 'goalsAgainst'],
  '2PtGA': ['2PtGA', '2Pt Goals Against', 'twoPointGoalsAgainst'],
  'FPTS': ['FPTS', 'Fantasy Pts', 'seasonTotalPts'],
  'FPTS/G': ['FPTS/G', 'Fantasy Pts/Gm', 'avgPPG']
  }
}





/**
 * Get stats mapping for player position
 */
function getMapForPosition(position: Positions): StatListType {
  switch (position.toUpperCase()) {
    case 'LSM':
      return defense
    case 'D':
      return defense
    case 'FO':
      return faceoff
    case 'G':
      return goalie
    case 'M':
      return offense
    case 'A':
      return offense
    case 'F':
      return offense
    case 'SSDM':
      return defense
    default:
      throw new Error(`unknown position $ position}`)
  }
}

export type StatMapType = { name: string; longName: string; field: string }

export function getStatList(position: Positions, statType: StatTypes): StatMapType[] {
  const maps = getMapForPosition(position)[statType]
  const result: StatMapType[] = []
  maps.map((key) => {
    const row = statFields[key]
    if (row) {
      result.push({
        name: row[0],
        longName: row[1],
        field: row[2],
      })
    }
  })
  return result
}
/**
 * Get stats mapping for player position
 */
export const getMapForStatGroup = (group: string): StatListType => {
  switch (group.toLowerCase()) {
    case 'offensive':
      return offense
    case 'defensive':
      return defense
    case 'faceoff':
      return faceoff
    case 'goalie':
      return goalie
    default:
      return offense
  }
}

export type FilteredResult = StatMapType & { value: number | string | null | undefined }

/**
 * Returning list of stats to use, used in getting player leaders or game breakdowns
 */
export const getStatsToUseList = (headerType: StatListHeader): string[] => {
  switch (headerType) {
    case 'ByPlayerPosition':
      return [
        'Attack',
        'Midfield',
        'Long Stick Midfield',
        'Short Stick Defensive Midfield',
        'Defense',
        'Faceoff',
        'Goalie',
        'Rookie',
      ]
    case 'ChampSeriesByPlayerPosition':
      return ['Field', 'Goalie']
    case 'GamePlayerGroups':
      return ['Offensive', 'Defensive', 'Faceoff', 'Goalie']

    case 'ByTeamGroups':
      return [
        'Offensive',
        'Defensive',
        'Faceoff',
        'Goalie',
        'Power Play',
        'Penalty Kill',
      ]
    default:
      return []
  }
}

export const renderPlayerStat = (
  plr: PlayerData,
  v: [string, string, string],
  seasonSelect: Season
) => {
  if (seasonSelect.seasonType === 'champseries') {
    if (!plr.champSeries?.stats || !(v[2] in plr.champSeries?.stats)) return ''
    let stat = plr.champSeries?.stats
      ? plr.champSeries?.stats[v[2] as keyof PlayersStatsData]
      : 0
    return ConvertStatValue(stat, v[2])
  } else if (seasonSelect.seasonType === 'post') {
    if (!plr.postStats || !(v[2] in plr.postStats)) return ''
    let stat = plr.postStats[v[2] as keyof PlayersStatsData] || 0
    return ConvertStatValue(stat, v[2])
  } else {
    if (v[0] === 'FPTS/G' || v[0] === 'FPTS') {
      //Convert fantasy stats
      if (!plr?.fantasy) return ''
      return ConvertStatValue(plr.fantasy[v[2]], v[2])
    }
    if (!plr.stats || !(v[2] in plr.stats)) return ''
    let stat = plr.stats[v[2] as keyof PlayersStatsData] || 0
    return ConvertStatValue(stat, v[2])
  }
}

export const renderPlayerWeekFantasyPoints = (
  plr: PlayerData,
  week: number
) => {
    if (!plr.fantasy?.seasonWeeklyPts || plr.fantasy.seasonWeeklyPts.length < 1) return ''
    let stat = plr.fantasy?.seasonWeeklyPts.find((x) => x.week === week)?.totalPts || 0
    return stat
}

export const getStatAvg = (stat: number, gamesPlayed?: number | null) => {
  if (!gamesPlayed || gamesPlayed === 0) return 0
  return stat / gamesPlayed
}
