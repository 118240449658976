import * as React from 'react'
import { IconProps } from './types'

export const Spinner: React.FC<IconProps & { color?: string }> = ({
  width,
  height,
  color,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 200 200"
  >
    <path
      className="symbolStrokeColor"
      strokeWidth="20"
      strokeLinecap="round"
      fill="none"
      d="m189,100c0,-49 -40,-89 -89,-89c-49,0 -89,40 -89,89"
      stroke={color}
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 100 100"
        to="360 100 100"
        dur="1s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)

export const Download: React.FC<IconProps> = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
    width={width}
    height={height}
  >
    <g id="Solid">
      <path d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z" />
      <path d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z" />
    </g>
  </svg>
)

export const Microphone: React.FC<IconProps> = ({ width, height, className }) => (
  <svg
    id="i-microphone"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    width={width}
    height={width}
  >
    <path d="M16 2 C12 2 12 6 12 6 L12 16 C12 16 12 20 16 20 20 20 20 16 20 16 L20 6 C20 6 20 2 16 2 Z M8 17 C8 17 8 24 16 24 24 24 24 17 24 17 M13 29 L19 29 M16 24 L16 29" />
  </svg>
)

export const Star: React.FC<IconProps> = ({ width, height, className, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
    width={width}
    height={height}
  >
    <path fill={fill} d="M114.6 500.8A27.2 27.2 0 0188 467.5l33-145-111.8-98a27.2 27.2 0 0115.4-47.6l147.8-13.4L231 26.7a27.2 27.2 0 0150 0l58.4 136.8 147.8 13.4a27.2 27.2 0 0115.5 47.6l-111.7 98 33 145a27.2 27.2 0 01-40.6 29.4L256 420.8 128.5 497a27.3 27.3 0 01-14 3.8zM256 388c4.8 0 9.6 1.3 14 4l120.2 71.8-31-136.9c-2.3-9.7 1-20 8.6-26.5l105.4-92.5L333.7 195c-10-.9-18.7-7.2-22.6-16.5L256 49.6l-55.2 129a27.1 27.1 0 01-22.5 16.5L38.7 207.7l105.5 92.5a27.2 27.2 0 018.6 26.6l-31 137 120.2-72c4.3-2.6 9.1-3.9 14-3.9zm-84.6-221.8zm169.1 0zm0 0" />
  </svg>
)

export const StarFilled: React.FC<IconProps> = ({ width, height, className, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
    width={width}
    height={height}
  >
    <path fill={fill} d="M114.4 500.7c-5.6 0-11.2-1.7-15.9-5.2-8.8-6.4-13-17.4-10.6-28l32.9-144.8L9.3 224.9c-8.2-7.2-11.3-18.5-8-28.9 3.4-10.3 12.5-17.7 23.4-18.7L172.2 164l58.3-136.5c4.3-10 14.1-16.5 25-16.5s20.7 6.5 25 16.5L338.8 164l147.5 13.4c10.9 1 20 8.3 23.4 18.7 3.4 10.3.3 21.7-7.9 28.9l-111.5 97.8 32.9 144.8c2.4 10.6-1.7 21.7-10.6 28-8.8 6.4-20.6 6.9-29.9 1.3l-127.2-76L128.3 497c-4.3 2.4-9.1 3.7-13.9 3.7zm56.7-334.1zm168.8-.1z" />
  </svg>
)

export const Clipboard: React.FC<React.PropsWithChildren<IconProps & {fill?: string}>> = ({ width, height, className, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 439 439"
    className={className}
    width={width}
    height={height}
    fill={fill}
  >
    <path fill={fill} d="M348 57.5h-39.7V39.7c0-5.7-6.3-8.3-12-8.3h-30.9C258.1 10.4 239.8 0 219 0a47.5 47.5 0 00-46.5 31.4h-30.3c-5.7 0-11.5 2.6-11.5 8.3v17.8H91A44.4 44.4 0 0047 99.8v299.4c0 23 21 39.7 44 39.7h257c23 0 43.9-16.7 43.9-39.7V99.8a44.4 44.4 0 00-44-42.3zm-196.5-5.2h28.8c5-.6 9-4.4 9.9-9.4 3-13.5 14.9-23.2 28.7-23.5a29.8 29.8 0 0128.2 23.5c1 5.1 5.3 9 10.5 9.4h29.8V94H151.5V52.3zM371 399.2c0 11.5-11.5 18.8-23 18.8H91c-11.6 0-23-7.3-23-18.8V99.8c1-12 11-21.2 23-21.4h39.6V105a11 11 0 0011.5 10h154.1c6 .3 11.3-4 12-10V78.4H348c12 .2 21.9 9.5 23 21.4v299.4z" />
    <path fill={fill} d="M179.2 233.6c-3.9-4.2-10.4-4.4-14.6-.6l-33.4 32-14.2-14.7c-3.9-4.1-10.4-4.4-14.6-.5-4 4.2-4 10.9 0 15.1l21.4 22c1.9 2 4.6 3.2 7.4 3 2.7 0 5.3-1 7.3-3l40.7-38.7a10 10 0 000-14.6zm150 22.4H209a10.4 10.4 0 100 21h120.2a10.4 10.4 0 100-21zm-150-106c-3.9-4.2-10.4-4.4-14.6-.5l-33.4 31.8-14.2-14.6c-3.9-4.1-10.4-4.4-14.6-.5-4 4.2-4 10.9 0 15.1l21.4 22c1.9 2 4.6 3.2 7.4 3.1 2.7 0 5.3-1.2 7.3-3.1l40.7-38.7a10 10 0 000-14.6zm150 22.4H209a10.4 10.4 0 100 21h120.2a10.4 10.4 0 100-21zm-150 144.8c-3.9-4.2-10.4-4.4-14.6-.6l-33.4 32-14.2-14.7c-3.9-4.2-10.4-4.4-14.6-.5-4 4.2-4 10.9 0 15.1l21.4 22c1.9 2 4.6 3.2 7.4 3 2.7 0 5.3-1 7.3-3l40.7-38.7a10 10 0 000-14.6zm150 22.4H209a10.4 10.4 0 100 21h120.2a10.4 10.4 0 100-21z" />
  </svg>
)

export const Play: React.FC<React.PropsWithChildren<IconProps & {fill?: string}>> = ({ fill, width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 510 510"
    className={className}
    width={width}
    height={height}
  >
    <path fill={fill} d="M204 369.8L357 255 204 140.2v229.6zM255 0C114.7 0 0 114.8 0 255s114.8 255 255 255 255-114.8 255-255S395.2 0 255 0zm0 459c-112.2 0-204-91.8-204-204S142.8 51 255 51s204 91.8 204 204-91.8 204-204 204z" />
  </svg>
)

export const Left: React.FC<React.PropsWithChildren<IconProps>> = ({ width, height, fill, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={className}
    width={width}
    height={height}
    fill={fill}
  >
  <path fill={fill} d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
  </svg>
)

export const Right: React.FC<React.PropsWithChildren<IconProps>> = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 100"
    className={className}
    width={width}
    height={height}
  >
    <polygon points="0,0 80,50 0,100" />
  </svg>
)

export const Info: React.FC<React.PropsWithChildren<IconProps>> = ({ width, height, fill, className }) => (
  <svg
    fill={ fill || "#000000"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
  >
    {' '}
    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
  </svg>
)
