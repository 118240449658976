export type VideoPlayerOptions = {
  controls: boolean
  control_bar: boolean
  autoplay: boolean
  responsive: boolean
  muted: boolean
  aspectRatio: string
  fullscreen_control: boolean
  skin: string
  playback_rates: number[]
  skip_buttons: {
    forward: number
    backward: number
  }
  picture_in_picture_control: boolean
  css: {
    progressColor: string
  }
  dock: boolean
  volume_orientation: string
  plugins: Plugin[]
  player: Player
}

type Player = {
    template: {
        name: string
        version: string
        version_alias: string | null
        locked: boolean
    }
}

type Plugin = {
  registry_id: string
  version: string
  options?: Record<string, any>
}

export const FULL_VIDEO_VIEW_HEIGHT = 85 // % of view height

export const getFullVideoViewHeight = (deviceSize: string) => {
  const h = {
    xs: 35,
    sm: 40,
    md: 60,
    lg: 85,
    xl: 85,
  }[deviceSize]

  return h ?? FULL_VIDEO_VIEW_HEIGHT

}

export const getVideoPlayerOptions = ({
  autoplay = false,
  aspectRatio = '16:9',
  muted = false,
}): VideoPlayerOptions => {
  return {
    controls: true,
    control_bar: true,
    autoplay,
    responsive: true,
    muted,
    aspectRatio,
    fullscreen_control: true,
    skin: 'sapphire',
    playback_rates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
    skip_buttons: {
      forward: 10,
      backward: 10,
    },
    picture_in_picture_control: false,
    css: {
      progressColor: 'rgb(0, 0, 0)',
    },
    dock: false,
    volume_orientation: 'horizontal',
    player: {
      template: {
        name: 'single-video-template',
        version: '7.23.0',
        version_alias: null,
        locked: false,
      },
    },
    plugins: [
      {
        registry_id: '@brightcove/videojs-thumbnails',
        version: '2.x',
      },
      {
        registry_id: '@brightcove/videojs-quality-menu',
        version: '3.x',
        options: {
          useResolutionLabels: true,
        },
      },
      {
        registry_id: '@brightcove/videojs-bc-airplay',
        version: '2.x',
      },
      {
        registry_id: '@brightcove/videojs-bc-ga',
        version: '2.x',
        options: {
          eventsToTrack: {
            video_impression: 'Video Impression',
            play_request: 'Play Request',
            video_engagement: 'Video Engagement',
          },
          tracker: '',
          trackerGA4: 'G-9VLQREESXN',
          paramsToTrackGA4: ['seconds_viewed', 'range', 'video_duration', 'player'],
        },
      },
    ],
  }
}
