import { styled } from '@mui/system'

export const CardLoadRoot = styled('div')(({ theme }) => ({
  width: '33%',
  textAlign: 'center',
  '& .tRow': {
    display: 'flex',
    margin: theme.spacing(3, 2),
    textAlign: 'center',
    '& .avatar': {
      marginRight: theme.spacing(1),
      textAlign: 'center',
    },
  },
}))

type TableLoadStyle = {
  fullHeight?: boolean
}

export const TableLoadRoot = styled('div')<TableLoadStyle>(({ fullHeight, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  textAlign: 'center',
  height: fullHeight ? '100vh' : 'auto',
  '& .tRow': {
    display: 'flex',
    margin: theme.spacing(2),
    width: '100%',
    '& .avatar': {
      marginRight: theme.spacing(1),
    },
  },
}))
