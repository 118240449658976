import { styled } from '@mui/system'

export const NavActionCon = styled('a')(({ theme }) => ({
  display: 'inline-block',
    background: '#ffcb06',
    borderRadius: 10,
    marginRight: 5,
    padding: '5px 15px',
    textDecoration: 'none',
    '& p': {
      color: '#000',
      fontSize: '1rem',
      fontWeight: 700,
      margin: 'auto 5px',
    },
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      top: 15,
      right: 60,
      marginRight: 15,
    },
}))

export const NavbarCon = styled('section')(({ theme }) => ({
  height: 70,
  background: '#000',
  margin: 0,
  [theme.breakpoints.down('lg')]: {
    height: 60,
  },
  '& .brand': {
    display: 'block',
    position: 'absolute',
    marginTop: 0,
    marginLeft: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
      marginLeft: 25,
      float: 'left',
    },
    '& a img': {
      maxHeight: 70,
      [theme.breakpoints.down('lg')]: {
        maxHeight: 60,
      },
    },
  },
  '& .navMain': {
    float: 'left',
    marginLeft: 200,
    '& .navMobile': {
      display: 'none',
      [theme.breakpoints.down('lg')]: {
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        background: '#000',
        height: 60,
        width: '100%',
      },
      '& .toggleContainer': {
        position: 'absolute',
        display: 'none',
        top: 0,
        left: 0,
        width: 41,
        height: 60,
        background: '#ffcb06',
        zIndex: 1,
        [theme.breakpoints.down('lg')]: {
          display: 'block',
        },
      },
    },
    '& .navList': {
      display: 'block',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      float: 'left',
      position: 'relative',
      '& li': {
        float: 'left',
        position: 'relative',
        '& a': {
          display: 'block',
          padding: '0 10px',
          lineHeight: '70px',
          background: 'transparent',
          color: '#ffffff',
          fontWeight: 500,
          fontSize: '1rem',
          textDecoration: 'none',
          '&:hover': {
            background: '#000',
            color: '#ffcb06',
          },
        },
        '& button': {
          display: 'block',
          padding: '0 10px',
          lineHeight: '70px',
          background: 'transparent',
          color: '#ffffff',
          fontWeight: 600,
          fontSize: '1rem',
          textDecoration: 'none',
          border: 'none',
          '&:hover': {
            background: '#000',
            color: '#ffcb06',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
  },
  '& .rightCol': {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    height: 70,
    [theme.breakpoints.down('lg')]: {
      height: 60,
    },
    '& .navProfile': {
      margin: 'auto 5px',
      right: 10,
    },
  },
}))

export const SubDropdown = styled('ul')(({ theme }) => ({
  position: 'absolute',
  background: '#000',
  zIndex: 99,
  boxShadow: '0 3px 12px rgba(0, 0, 0, 015)',
  '& li': {
    minWidth: 190,
    '& a': {
      height: 50,
      padding: 5,
    },
  },
  [theme.breakpoints.down('lg')]: {
    position: 'static',
  },
}))

type mobileNavParams = {
  open: boolean
}


export const MobileNavCon = styled('div')<mobileNavParams>(({ open, theme }) => ({
  display: open ? 'block' : 'none',
}))

export const MobileNavList = styled('ul')<mobileNavParams>(({ open, theme }) => ({
  display: open ? 'flex' : 'none',
  flexDirection: 'column',
  textAlign: 'left',
  position: 'relative',
  top: 40,
  background: '#000',
  width: '100%',
  height: '100%',
  zIndex: 10,
  padding: '15px 20px',
  '& a': {
    padding: '0 10px',
    lineHeight: '40px',
    background: 'transparent',
    color: '#ffffff',
    fontWeight: 500,
    fontSize: '1rem',
    textDecoration: 'none',
    '&:hover': {
      background: '#000',
      color: '#ffcb06',
    },
  },
  '& button': {
    padding: '0 10px',
    lineHeight: '40px',
    background: 'transparent',
    color: '#ffffff',
    fontWeight: 600,
    fontSize: '1rem',
    textDecoration: 'none',
    border: 'none',
    '&:hover': {
      background: '#000',
      color: '#ffcb06',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))
