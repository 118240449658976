import { colors, fontSizes } from '../../Constants/consts'
import { styled } from '@mui/system'

type HeaderProps = {
  justify?: 'left' | 'center' | 'right'
  colorTheme?: 'light' | 'dark'
  color?: string
  fontSize?: number
  sideMargins?: number
  vertMargins?: number[]
}

export const Head = styled('h1')<HeaderProps>(
  ({ justify, fontSize, colorTheme, color, sideMargins, vertMargins, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.large,
    color: colorTheme === 'dark' ? colors.white : color ? color : colors.text,
    textAlign: justify || 'left',
    fontWeight: 'bold',
    margin: theme.spacing(vertMargins ? vertMargins[0] : 2, sideMargins ? sideMargins : 0, vertMargins ? vertMargins[1] : 2, ),
    [theme.breakpoints.down('md')]: {
      fontSize: fontSize ? fontSize * 0.7 : fontSizes.medium,
      margin: theme.spacing(vertMargins ? vertMargins[0] * 0.75 : 1, sideMargins ? sideMargins * 0.75 : 0, vertMargins ? vertMargins[1] * 0.75 : 1),
    },
  })
)

export const Head2 = styled('h2')<HeaderProps>(
  ({ justify, fontSize, colorTheme, color, sideMargins, vertMargins, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.semiLarge,
    color: colorTheme === 'dark' ? colors.white : color ? color : colors.text,
    textAlign: justify || 'left',
    fontWeight: 'bold',
    margin: theme.spacing(vertMargins ? vertMargins[0] : 1.5, sideMargins ? sideMargins : 0, vertMargins ? vertMargins[1] : 1.5, ),
    [theme.breakpoints.down('md')]: {
      fontSize: fontSize ? fontSize * 0.7 : fontSizes.medium,
      margin: theme.spacing(vertMargins ? vertMargins[0] * 0.75 : 1, sideMargins ? sideMargins * 0.75 : 0, vertMargins ? vertMargins[1] * 0.75 : 1),
    },
  })
)

export const Head3 = styled('h3')<HeaderProps>(
  ({ justify, fontSize, colorTheme, color, sideMargins, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.medium,
    color: colorTheme === 'dark' ? colors.white : color ? color : colors.text,
    textAlign: justify || 'left',
    fontWeight: 'bold',
    margin: sideMargins ? theme.spacing(1, sideMargins) : theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSize ? fontSize * 0.7 : fontSizes.small,
      margin: sideMargins ? theme.spacing(0.75, sideMargins) : theme.spacing(0.75, 0),
    },
  })
)

export const Desc = styled('p')<HeaderProps>(
  ({ justify, fontSize, colorTheme, color, sideMargins, vertMargins, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.medium,
    color: colorTheme === 'dark' ? colors.white : color ? color : colors.helperText,
    textAlign: justify || 'left',
    fontWeight: 500,
    margin: theme.spacing(vertMargins ? vertMargins[0] : 1, sideMargins ? sideMargins : 0, vertMargins ? vertMargins[1] : 1),
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSize ? fontSize * 0.8 : fontSizes.small,
      margin: theme.spacing(vertMargins ? vertMargins[0] * 0.75 : 1, sideMargins ? sideMargins * 0.75 : 0, vertMargins ? vertMargins[1] * 0.75 : 1),
    },
  })
)
